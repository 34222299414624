import React from 'react'
import './index.scss'
import { Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import RankingIcon from '../../../../assets/icons/Ranking.png'
import GemIcon from '../../../../assets/icons/Gem.png'

const WinGame = ({ onClickReward }) => {
  return (
    <Row className="win-modal">
      <div className="win-modal-container">
        <div className="you-win">
          <h2 className="mb-4 text-center white-text">YOU WIN!</h2>
        </div>
        <div className="mb-5 mt-5 d-flex justify-content-evenly">
          <div className="win-firstplus">
            <span className="span-firstplus">
              <img src={GemIcon} alt="gem icon" />
              +1200
            </span>
          </div>
          <div className="win-secondplus">
            <span className="span-secondplus">
              <img src={RankingIcon} alt="rank icon" />
              +1200
            </span>
          </div>
        </div>
        <div className="mb-4 d-flex justify-content-evenly">
          <div className="button-parent">
            <Button className="home-btn" onClick={onClickReward}>
              HOME
            </Button>
          </div>
          <div className="button-play">
            <Button className="play-btn" onClick={onClickReward}>
              PLAY AGAIN
            </Button>
          </div>
        </div>
      </div>
    </Row>
  )
}

export default WinGame

import React, { useState } from 'react'
import './HomeHeader.scss'
import GemIcon from '../../../assets/icons/Gem.png'
import RankingIcon from '../../../assets/icons/Ranking.png'
import useUserStore from '../../../store/UserStore'
import Card from '../../../assets/icons/Card.png'
import Back from '../../../assets/icons/Back.png'
import Admin from '../../../assets/icons/Admin.png'
import useGameStore from '../../../store/GameStore'
import { Button, Modal } from 'react-bootstrap'
import LoginComponent from '../../Login/LoginComponent'
import RegistrationComponent from '../../Login/RegistrationComponent/RegistrationComponent'
import ForgotPasswordComponent from '../../Login/ForgotPasswordComponent/ForgotPasswordComponent'
import Swal from 'sweetalert2'
import { EmailIcon } from '../../../assets/icons'
import { USER_ROLES } from '../../../constants'
import { useNavigate } from 'react-router-dom'

export const HomeHeader = () => {
  const { socket } = useGameStore()
  const { user, setUser, setUserId, setToken, setRefreshToken, setGuestId, rank, fetchRank, level, completed } = useUserStore()
  const [loginVisible, setLoginVisible] = useState(false)
  const [registerVisible, setRegisterVisible] = useState(false)
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false)
  const gemsBalance = user ? user.gems : '0.00'
  const zero = num => (num < 10 ? `0${num}` : num)
  const onSuccess = async obj => {
    setLoginVisible(false)
    setRegisterVisible(false)
    setUserId(obj._id)
    setGuestId(null)
    setUser(obj)
    setToken(obj.accessToken)
    setRefreshToken(obj.refreshToken)
    fetchRank()
    try {
      if (typeof socket !== 'undefined') {
        socket.disconnect()
        socket.auth = { userId: obj._id }
        socket.connect()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There is a problem in Socket connection'
        })
      }
    } catch (err) {
      console.log('err---', err)
    }
  }

  const toggleLogin = async () => {
    setLoginVisible(state => !state)
  }
  const toggleRegister = async () => {
    setRegisterVisible(state => !state)
  }
  const toggleForgotPassword = async () => {
    setForgotPasswordVisible(state => !state)
  }

  const navigate = useNavigate()

  const navigateAdmin = () => {
    navigate('/admin/dashboard')
  }

  const navigateCollection = () => {
    navigate('/collection')
  }
  return (
    <>
      <div className="game-board-loginscreen">
        {window?.location?.pathname !== '/'
          ? <div className="game-board-card d-flex align-items-center ">
              <button onClick={() => navigate(-1)} className='bg-transparent border-0 me-1'>
                <img src={Back} alt="card" className="img-fluid admin-img " />
              </button>
            </div>
          : <div className="game-board-card d-flex align-items-center ">
              {user?.role === USER_ROLES.ADMIN &&
                  <button onClick={navigateAdmin} className='bg-transparent border-0 me-1'>
                    <img src={Admin} alt="card" className="img-fluid admin-img " />
                  </button>
              }
              {user &&
                <button onClick={navigateCollection} className='bg-transparent border-0 me-1'>
                  <img src={Card} alt="card" className="img-fluid card-img " />
                </button>
              }
            </div>
        }
        {user
          ? <div className="d-flex justify-content-end align-items-center ">
              <div className="xp-container">
                <div className="star-container">
                  <div className='level-text'>{zero(level)}</div>
                </div>
                <div className='progress-bar' style={{ width: completed }} />
              </div>
              <div className="user-detail ms-3 ms-md-4 ms-lg-5">
                <span className="span-plus d-flex align-items-center">
                  <img src={GemIcon} alt="eye-icon" className="eye-icon img-fluid" />
                  {gemsBalance}
                </span>
              </div>
              {rank && (
                <div className="user-detail ms-3 ms-md-4 ms-lg-5">
                  <span className="span-plus d-flex align-items-center">
                    <img src={RankingIcon} alt="icon-rank" className="eye-icon img-fluid" />
                    {rank}
                  </span>
                </div>
              )}
            </div>
          : <div className='d-flex justify-content-end align-items-center email-container-parent '>
              <Button onClick={toggleLogin} className="email-container">
                  <EmailIcon height={35} width={35} fill='#fff' />
              </Button>
            </div>}
      </div>

      <Modal centered show={loginVisible} onHide={toggleLogin}>
        <LoginComponent
          onSuccess={onSuccess}
          toggleLogin={toggleLogin}
          toggleRegister={toggleRegister}
          toggleForgotPassword={toggleForgotPassword}
        />
      </Modal>
      <Modal centered show={registerVisible} onHide={toggleRegister}>
        <RegistrationComponent
          onSuccess={onSuccess}
          toggleLogin={toggleLogin}
          toggleRegister={toggleRegister}
        />
      </Modal>
      <Modal centered show={forgotPasswordVisible} onHide={toggleForgotPassword}>
        <ForgotPasswordComponent
          toggleForgotPassword={toggleForgotPassword}
          toggleLogin={toggleLogin}
        />
      </Modal>
    </>
  )
}

export default HomeHeader

import React, { useState } from 'react'
import { Col, Form, Row, Spinner, Button } from 'react-bootstrap'
import './GuestRegistration.scss'
import axios from 'axios'
import { toastErrorMixin } from '../../../../utils/interact'
import { validateEmail } from '../../../../libraries/common'
import useUserStore from '../../../../store/UserStore'
import useGameStore from '../../../../store/GameStore'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const GuestRegistration = ({ onClickOkay, isUserWon }) => {
  const { socket } = useGameStore()
  const { setUserId, setUser, setGuestId, setToken } = useUserStore()
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      })
    }
  }

  const findFormErrors = () => {
    const { email, password, userName } = form
    const newErrors = {}
    if (!email || email === '') newErrors.email = 'Email cannot be blank!'
    else if (!validateEmail(email)) newErrors.email = 'Email should be valid!'
    if (!userName || userName === '') newErrors.userName = 'Username cannot be blank!'
    if (!password || password === '') newErrors.password = 'Password cannot be blank!'
    else if (password.length < 8) {
      newErrors.password = 'Password should be at least 8 character long!'
    }

    return newErrors
  }

  const navigate = useNavigate()

  const handleSubmit = async e => {
    e.preventDefault()
    const newErrors = findFormErrors()
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setSubmitting(true)
      try {
        const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/signup'
        const res = await axios.post(url, form)
        if (res && res.data && res.data.status === 200) {
          setUserId(res.data.data._id)
          setGuestId(null)
          setUser(res.data.data)
          setToken(res.data.data.accessToken)
          if (typeof socket !== 'undefined') {
            socket.disconnect()
            socket.auth = {
              userId: res.data.data._id
            }
            socket.connect()
            navigate('/')
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'There is a problem in Socket connection'
            })
            onClickOkay()
          }
        } else {
          toastErrorMixin.fire({
            title: res.data.message
          })
        }
      } catch (err) {
        toastErrorMixin.fire({
          title: 'Error logging in.'
        })
        onClickOkay()
      } finally {
        setSubmitting(false)
      }
    }
  }
  return (
    <Row className="won-lost-registration-area">
      <div className="register-container">
        <h1 className="title">Create your account</h1>
        <div className="title-tagline">
          {isUserWon
            ? 'Congratulations! Please register to start earning rewards at ChessX!'
            : 'You lost! Please register to start your learning adventure at ChessX!'}
        </div>
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom02" className="form-container">
              <Form.Label className="input-label">Email</Form.Label>
              <Form.Control
                className="inputbox"
                required
                type="email"
                placeholder="Enter your email"
                onChange={e => setField('email', e.target.value)}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom03">
              <Form.Label className="input-label">Username</Form.Label>
              <Form.Control
                className="inputbox"
                required
                type="text"
                placeholder="Enter your username"
                onChange={e => setField('userName', e.target.value)}
                isInvalid={!!errors.userName}
              />
              <Form.Control.Feedback type="invalid">{errors.userName}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom05">
              <Form.Label className="input-label">Password</Form.Label>
              <Form.Control
                className="inputbox"
                type="password"
                placeholder="Enter at least 8 character"
                required
                minLength={8}
                onChange={e => setField('password', e.target.value)}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 mt-5">
            <Col>
              <Button disabled={submitting} type="submit" className="register-btn">
                {submitting ? <Spinner size="sm" animation="border" /> : 'Register'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Row>
  )
}

export default GuestRegistration

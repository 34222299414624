import React, { useState } from 'react'
import { Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import './ForgotPasswordComponent.scss'
import { validateEmail } from '../../../libraries/common'
import OTPInput from '../../Common/OTPInput'
import useUserStore from '../../../store/UserStore'
import { EmailIcon, EyeIcon, EyeClosedIcon, LockIcon } from '../../../assets/icons'

const ForgotPasswordComponent = ({ toggleForgotPassword, toggleLogin }) => {
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [showOtpView, setShowOtpView] = useState(false)
  const { sendForgotPassOtp, resetPassword } = useUserStore()

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
    // Check and see if errors exist, and remove them from the error object:
    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      })
    }
  }
  const findFormErrors = () => {
    const { email } = form
    const newErrors = {}
    if (!email) newErrors.email = 'Email cannot be blank!'
    else if (!validateEmail(email)) newErrors.email = 'Email should be valid!'

    return newErrors
  }

  const findOTPFormErrors = () => {
    const { forgotPassOtp, password, password2 } = form
    const newErrors = {}
    if (!forgotPassOtp) newErrors.otp = 'OTP cannot be blank!'
    if (!password) newErrors.password = 'Password cannot be blank!'
    else if (password.length < 7) {
      newErrors.password = 'Password should be at least 8 character long!'
    }
    if (!password2) newErrors.password2 = 'Password cannot be blank!'
    else if (password2.length < 7) {
      newErrors.password2 = 'Password should be at least 8 character long!'
    } else if (password2 !== password) {
      newErrors.password2 = 'Password does not match!'
    }
    return newErrors
  }

  const handleSendOTP = async e => {
    e.preventDefault()
    const newErrors = findFormErrors()
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setSubmitting(true)
      try {
        const res = await sendForgotPassOtp(form.email)
        if (res) setShowOtpView(state => !state)
      } catch (err) {
        toggleLogin()
        toggleForgotPassword()
      } finally {
        setSubmitting(false)
      }
    }
  }
  const handleResendOTP = async e => {
    await sendForgotPassOtp(form.email)
  }

  const handleResetPassword = async e => {
    e.preventDefault()
    const newErrors = findOTPFormErrors()
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setSubmitting(true)
      try {
        const res = await resetPassword(form)
        if (res) {
          toggleLogin()
          toggleForgotPassword()
        }
      } catch (err) {
        console.log('error', err)
        toggleLogin()
        toggleForgotPassword()
      } finally {
        setSubmitting(false)
      }
    }
  }
  const [passwordShown, setPasswordShown] = useState(false)
  const togglePass = () => {
    setPasswordShown(state => !state)
  }
  const [rePasswordShown, setRePasswordShown] = useState(false)
  const toggleRePass = () => {
    setRePasswordShown(state => !state)
  }
  return (
    <Row className="forgot-password-area">
      <div className="forgot-container">
        {!showOtpView
          ? (
          <>
            <p className="title my-5 col-12 mx-auto text-center">
              Enter your email and we’ll send you a link to reset your password.
            </p>
            <Form noValidate onSubmit={handleSendOTP}>
              <Row className="mb-5">
                <Form.Group as={Col} md="12" controlId="validationCustom02" className="form-container">
                  <InputGroup hasValidation={true} className={`${errors.email ? 'is-invalid' : ''}`}>
                    <InputGroup.Text>
                      <EmailIcon height={20} width={20} fill="#737171" />
                    </InputGroup.Text>
                    <Form.Control
                      className="inputboxEmail"
                      required
                      type="email"
                      placeholder="Email"
                      onChange={e => setField('email', e.target.value)}
                      isInvalid={!!errors.email}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div className="email-button-parent mt-5">
                    <Button disabled={submitting} type="submit" className="send-email-btn">
                      {submitting ? <Spinner size="sm" animation="border" /> : 'SEND'}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div className="email-button-parent mb-5">
                    <Button
                      disabled={submitting}
                      className="send-email-btn"
                      onClick={toggleForgotPassword}
                    >
                      CANCEL
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </>
            )
          : (
          <Form noValidate onSubmit={handleResetPassword}>
            <div className="update-password-container">
              <h1 className="update-password">UPDATE YOUR PASSWORD</h1>
              <hr className="my-1 col-lg-10 mx-auto"></hr>
              <div className="registered-email-container">
                <h1 className="registered-email mt-4">
                  Enter 6-digit code you received in your registered email
                </h1>
              </div>
            </div>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="12"
                controlId="validationCustom03"
                className="form-container"
              >
                <OTPInput
                  autoFocus
                  isNumberInput
                  length={6}
                  className="otpContainer"
                  inputClassName={`otp-inputbox ${errors.otp ? 'otp-error' : ''}`}
                  onChangeOTP={forgotPassOtp => setField('forgotPassOtp', forgotPassOtp)}
                />
                {!!errors.forgotPassOtp && (
                  <div className="otp-error-msg">{errors.forgotPassOtp}</div>
                )}
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom05" className="form-container">
              <InputGroup hasValidation={true} className={`${errors.password ? 'is-invalid' : ''}`}>
                <InputGroup.Text>
                  <LockIcon height={20} width={20} />
                </InputGroup.Text>
                <Form.Control
                  className="form-password"
                  type={passwordShown ? 'text' : 'password'}
                  placeholder="Password"
                  required
                  minLength={8}
                  onChange={e => setField('password', e.target.value)}
                  isInvalid={!!errors.password}
                />
                <InputGroup.Text>
                  <i onClick={togglePass}>
                    {passwordShown
                      ? <EyeClosedIcon height={20} width={20} />
                      : <EyeIcon height={20} width={20} />
                    }
                  </i>
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom06">
              <InputGroup hasValidation={true} className={`${errors.password2 ? 'is-invalid' : ''}`}>
                <InputGroup.Text>
                  <LockIcon height={20} width={20} />
                </InputGroup.Text>
                <Form.Control
                  className="form-password"
                  type={rePasswordShown ? 'text' : 'password'}
                  placeholder="Re-enter Password"
                  required
                  minLength={8}
                  onChange={e => setField('password2', e.target.value)}
                  isInvalid={!!errors.password2}
                />
                <InputGroup.Text>
                  <i onClick={toggleRePass}>
                    {rePasswordShown
                      ? <EyeClosedIcon height={20} width={20} />
                      : <EyeIcon height={20} width={20} />
                    }
                  </i>
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors.password2}</Form.Control.Feedback>
            </Form.Group>
            </Row>
            <div className="mb-3 d-flex justify-content-end">
              <div className="resend-otp" onClick={handleResendOTP}>
                Resend OTP?
              </div>
            </div>
            <Row className="mb-3">
              <Col>
                <div className="rpasword-button-parent">
                  <Button disabled={submitting} type="submit" className="rpasword-button-child">
                    {submitting ? <Spinner size="sm" animation="border" /> : 'RESET PASSWORD'}
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="rpasword-button-parent mb-5">
                  <Button
                    disabled={submitting}
                    className="rpasword-button-child"
                    onClick={toggleForgotPassword}
                  >
                    CANCEL
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
            )}
      </div>
    </Row>
  )
}

export default ForgotPasswordComponent

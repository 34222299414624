import React, { useEffect, useState } from 'react'
import './Home.scss'
import WebBackGround from '../../assets/img/web-background.png'
import MobileBackGround from '../../assets/img/mobile-background.png'
import Match from '../../components/Home/Match'
import useUserStore from '../../store/UserStore'

const Homescreen = () => {
  const [windowWidth, setWindowWidth] = useState(window.screen.width)
  useEffect(() => {
    window.addEventListener('resize', changeWidth)
    return () => window.removeEventListener('resize', changeWidth)
  }, [])
  const changeWidth = () => {
    setWindowWidth(window.screen.width)
  }

  const [matching, setMatching] = useState(false)

  const stopMatching = () => {
    setMatching(false)
  }

  const { rank, fetchRank } = useUserStore()

  useEffect(() => {
    fetchRank()
  }, [])

  return (
    <>
      <div className="game-board-homescreen">
        <img
          src={windowWidth < 426 ? MobileBackGround : WebBackGround}
          className="background-image"
        />
      </div>
      <Match matching={matching} setMatching={setMatching} rank={rank || 1200} stopMatching={stopMatching} />
    </>
  )
}
export default Homescreen

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Navigate, Outlet } from 'react-router-dom'
import { USER_ROLES } from '../../../constants'
import useUserStore from '../../../store/UserStore'
import Sidebar from '../Sidebar'
import './AdminLayout.scss'

const AdminLayout = ({ children }) => {
  const { user } = useUserStore()

  if (user?.role !== USER_ROLES.ADMIN) {
    return <Navigate to="/" replace />
  }
  return (
    <Container fluid className="admin-layout-container">
      <Container className="admin-page-container" fluid="md">
        <Row className="admin-container">
          <Col md={2}>
            <Container className="sidebar-container">
              <Sidebar />
            </Container>
          </Col>
          <Col md={10}>
            <Container className="detail-container">
              {children}
              <Outlet />
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default AdminLayout

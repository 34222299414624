import React from 'react'
import '../ExitGame/index.scss'
import { Button } from 'react-bootstrap'

const ExitModal = ({ onClickOkay, onClickNo }) => {
  return (
    <div className="exit-modal">
      <div className="exit-modal-container">
        <div className="you-exit">
          <h2 className="mb-4 text-center white-text">EXIT</h2>
        </div>
        <div className="mb-5 mt-5 d-flex justify-content-evenly">
          <p className="exit-p">Are you sure you want to resign?</p>
        </div>
        <div className="mb-4 d-flex justify-content-evenly">
          <div className="button-yes">
            <Button onClick={onClickOkay} className="yes-btn">
              YES
            </Button>
          </div>
          <div className="button-no">
            <Button onClick={onClickNo} className="no-btn">
              NO
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExitModal

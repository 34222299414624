import React, { useCallback, useEffect, useState } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'

import useUserStore from '../../store/UserStore'
import { fetchRanking } from '../../components/Play/Profile/RankingApi'
import { useQueryClient } from 'react-query'

import './DailyPuzzle.scss'
import CustomChessBoard from '../../components/CustomChessBoard'
import PuzzleHeader from '../../components/Layout/PuzzleHeader'
import CButton from '../../components/Common/CButton'
import { HintIcon, ReloadIcon, RightArrowIcon } from '../../assets/icons'
import { FacebookIcon, TwitterIcon, InstagramIcon } from '../../assets/social'
import UserIcon from '../../assets/img/user.png'
import LoginComponent from '../../components/Login/LoginComponent'
import useGameStore from '../../store/GameStore'
import Swal from 'sweetalert2'
import RegistrationComponent from '../../components/Login/RegistrationComponent/RegistrationComponent'
import ForgotPasswordComponent from '../../components/Login/ForgotPasswordComponent/ForgotPasswordComponent'
import axiosInstance from '../../network/axiosInstance'
import { toastErrorMixin } from '../../utils/interact'

const DailyPuzzle = () => {
  const { socket } = useGameStore()
  const { setUser, setUserId, setToken, setGuestId, fetchRank, user } = useUserStore()
  const queryClient = useQueryClient()
  const [loginVisible, setLoginVisible] = useState(false)
  const [registerVisible, setRegisterVisible] = useState(false)
  const [fen, setFen] = useState('')
  const [result, setResult] = useState({
    text: '',
    style: ''
  })
  const [bestLine, setBestLine] = useState('')
  const [solutionState, setSolutionState] = useState('')
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false)

  useEffect(() => {
    return () => {
      queryClient.cancelQueries(['USERRANK'])
    }
  }, [])

  useEffect(() => {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/puzzle/daily'
    setSolutionState('initial')
    setBestLine('')
    axiosInstance.get(url).then((result) => {
      setFen(result.data.fen)
      setBestLine(result.data.moves)
    }).catch((e) => {
      toastErrorMixin.fire({
        title: 'Error getting daily puzzle.'
      })
    })
  }, [user])

  const { data } = fetchRanking() || {}

  const onSuccess = async obj => {
    setLoginVisible(false)
    setRegisterVisible(false)
    setUserId(obj._id)
    setGuestId(null)
    setUser(obj)
    setToken(obj.accessToken)
    fetchRank()
    try {
      if (typeof socket !== 'undefined') {
        socket.disconnect()
        socket.auth = { userId: obj._id }
        socket.connect()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There is a problem in Socket connection'
        })
      }
    } catch (err) {
      console.log('err---', err)
    }
  }

  const toggleLogin = async () => {
    setLoginVisible(state => !state)
  }

  const resetPuzzle = useCallback(
    () => {
      const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/puzzle/daily'
      setBestLine('')
      setFen('')
      axiosInstance.get(url).then((result) => {
        setFen(result.data.fen)
        setBestLine(result.data.moves)
        setSolutionState('initial')
      }).catch((e) => {
        toastErrorMixin.fire({
          title: 'Error getting daily puzzle.'
        })
      })
    },
    []
  )

  const toggleRegister = async () => {
    setRegisterVisible(state => !state)
  }

  const toggleForgotPassword = async () => {
    setForgotPasswordVisible(state => !state)
  }
  useEffect(() => {
    switch (solutionState) {
      case 'initial':
        return setResult((result) => {
          return {
            style: 'default',
            text: 'Make your first move…'
          }
        })
      case 'correct':
        return setResult((result) => {
          return {
            style: 'success',
            text: 'Looks good…'
          }
        })
      case 'wrong':
        return setResult((result) => {
          return {
            style: 'error',
            text: 'Not quite…'
          }
        })
      case 'complete':
        return setResult((result) => {
          return {
            style: 'success',
            text: 'Solved 🎉'
          }
        })
      case 'won':
        return setResult((result) => {
          return {
            style: 'success',
            text: 'You won!'
          }
        })
      case 'lost':
        return setResult((result) => {
          return {
            style: 'error',
            text: 'You lost!'
          }
        })
      case 'draw':
        return setResult((result) => {
          return {
            style: 'default',
            text: 'No winners today!'
          }
        })
      default:
        break
    }
  }, [solutionState])
  return (
    <>
      <Modal centered show={loginVisible} onHide={toggleLogin}>
        <LoginComponent
          onSuccess={onSuccess}
          toggleLogin={toggleLogin}
          toggleRegister={toggleRegister}
          toggleForgotPassword={toggleForgotPassword}
        />
      </Modal>
      <Modal centered show={registerVisible} onHide={toggleRegister}>
        <RegistrationComponent
          onSuccess={onSuccess}
          toggleLogin={toggleLogin}
          toggleRegister={toggleRegister}
        />
      </Modal>
      <Modal centered show={forgotPasswordVisible} onHide={toggleForgotPassword}>
        <ForgotPasswordComponent
          toggleForgotPassword={toggleForgotPassword}
          toggleLogin={toggleLogin}
        />
      </Modal>
      <Container fluid className="puzzle-page-container">
        <PuzzleHeader />
        <Row className="mt-5 justify-content-center">
          <Col xs={0} md={3} className='d-none d-sm-block'>
            <Container fluid className='side-container'>
              <div className='side-container-title mt-3'>
                Leaderboard
                <div className='divider' />
              </div>
              <div className='side-container-description me-5 ms-5 mt-auto mb-5'>
                You are not logged in please login to see your rank on leaderboard.
              </div>
              <div className='mb-auto'>
                <CButton onClick={toggleLogin}>Login</CButton>
              </div>
            </Container>
          </Col>
          <Col xs={12} md={4}>
            <CustomChessBoard fen={fen} background={'grass'} setSolutionState={setSolutionState} bestLine={bestLine} />
          </Col>
          <Col xs={12} md={3} className='d-none d-sm-block'>
            <Container fluid className='side-container'>
              <img className='mt-4' src={UserIcon}></img>
              <div className='side-container-title mt-3'>
                {user?.userName ? user.userName : 'Guest'}
              </div>
              <Row className='mt-3 profile-detail'>
                <Col xs={10}>
                  <div>
                    Puzzle Rating (#29032)
                  </div>
                  <div>
                    Target time
                  </div>
                </Col>
                <Col xs={2}>
                  <div>
                    {data?.rank ? data.rank : 1200}
                  </div>
                  <div>
                    04:00
                  </div>
                </Col>
              </Row>
              <Container fluid className={`${result.style} result-container p-4 m-4`}>
                <p className='result-text'>{result.text}</p>
              </Container>
              <div className='social-title mt-4'>Share</div>
              <Row className='justify-content-center mt-2'>
                <Col xs={2}>
                  <img className='social-icon' src={FacebookIcon} />
                </Col>
                <Col xs={2}>
                  <img className='social-icon' src={TwitterIcon} />
                </Col>
                <Col xs={2}>
                  <img className='social-icon' src={InstagramIcon} />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="mt-2 justify-content-center">
          <Col md={3} />
          <Col xs={12} md={4}>
            <Row className='justify-content-between'>
              <Col xs={4}>
                <CButton secondary w={50} h={50} disabled={solutionState === 'initial' || solutionState === 'correct'} onClick={resetPuzzle}><ReloadIcon className='icon' /></CButton>
              </Col>
              <Col xs={5} md={4} className='me-3'>
                <Row className='justify-content-between'>
                  <CButton secondary w={50} h={50}><HintIcon className='icon' /></CButton>
                  <CButton h={50}><RightArrowIcon /></CButton>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={3} />
        </Row>
      </Container>
    </>

  )
}

export default DailyPuzzle

import React, { useCallback, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const CDropdown = ({ data, selectedItem, onSelect }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)

  const toggleDropDown = useCallback(() => {
    setDropDownOpen(prevState => !prevState)
  }, [])

  const handleSelect = useCallback(
    item => {
      onSelect(item)
      toggleDropDown()
    },
    [onSelect, toggleDropDown]
  )

  return (
    <>
      <Dropdown show={dropDownOpen} onToggle={toggleDropDown}>
        <Dropdown.Toggle
          className="nav-link dropdown-toggle arrow-none"
          as="a"
          role="button"
          onClick={toggleDropDown}
        >
          <span className="align-middle d-none d-lg-inline-block">
            {selectedItem?.name}
          </span>
          <i className="ri-arrow-down-s-line d-none d-sm-inline-block align-middle" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-animated">
          {(data || [])?.map((item, idx) => {
            return (
              <Link
                className="dropdown-item"
                key={idx + item?.name}
                onClick={() => handleSelect(item)}
              >
                <span className="align-middle">{item.name}</span>
              </Link>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default CDropdown

import { useQuery } from 'react-query'
import axiosInstance from '../../../network/axiosInstance'

const getRanking = async () => {
  const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/ranking/userRank'
  return await axiosInstance.get(url)
}
export const fetchRanking = () => {
  const { isLoading, data, isRefetching, refetch } = useQuery(
    ['USERRANK'],
    async () => {
      const result = await getRanking()
      return result.data
    },
    {
      keepPreviousData: true
    }
  )
  return { isLoading, data: data || {}, isRefetching, refetch }
}

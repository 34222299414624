import create from 'zustand'
import { persist } from 'zustand/middleware'
import axiosInstance from '../network/axiosInstance'

import { toastMixin } from '../utils/interact'

const useAdminStore = create(
  persist(
    (set, get) => ({
      updateMarketplace: async (marketplaceId, params) => {
        try {
          if (!marketplaceId) {
            return
          }

          const marketplaceUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/marketplace/update-marketplace'
          const res = await axiosInstance.put(marketplaceUrl, params)
          if (res?.status === 200) {
            toastMixin.fire({ title: 'Your have successfully updated the item!' })
          }
        } catch (e) {
          toastMixin.fire({
            title: `Error updating NFT: ${e?.response?.data?.error}`,
            icon: 'error'
          })
        }
      },
      fetchUsers: async params => {
        try {
          const userUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/'
          const res = await axiosInstance.get(userUrl, { params })
          if (res?.status === 200) {
            return res?.data
          }
        } catch (e) {
          toastMixin.fire({
            title: `Error fetching users: ${e?.response?.data?.error}`,
            icon: 'error'
          })
        }
      },
      fetchUserData: async params => {
        try {
          const userUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/userDetail'
          const res = await axiosInstance.get(userUrl, { params })
          if (res?.status === 200) {
            return res?.data
          }
        } catch (e) {
          toastMixin.fire({
            title: `Error fetching ranks: ${e?.response?.data?.error}`,
            icon: 'error'
          })
        }
      },
      fetchUserRank: async params => {
        try {
          const userUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/ranking/userRank'
          const res = await axiosInstance.get(userUrl, { params })
          if (res?.status === 200) {
            return res?.data
          }
        } catch (e) {
          toastMixin.fire({
            title: `Error fetching ranks: ${e?.response?.data?.error}`,
            icon: 'error'
          })
        }
      },
      fetchUserCollection: async params => {
        try {
          const userUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/collection/userCollection'
          const res = await axiosInstance.get(userUrl, { params })
          if (res?.status === 200) {
            return res?.data
          }
        } catch (e) {
          toastMixin.fire({
            title: `Error fetching collection: ${e?.response?.data?.error}`,
            icon: 'error'
          })
        }
      },
      fetchOpeningData: async params => {
        try {
          const openingUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/opening/all'
          const res = await axiosInstance.get(openingUrl)
          if (res?.status === 200) {
            return res?.data
          }
        } catch (e) {
          toastMixin.fire({
            title: `Error fetching opening data: ${e?.response?.data?.error}`,
            icon: 'error'
          })
        }
      },
      updateOpeningData: async (params) => {
        try {
          const openingUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/opening/'
          const res = await axiosInstance.put(openingUrl, params)
          if (res?.status === 200) {
            toastMixin.fire({ title: 'Your have successfully updated the item!' })
          }
        } catch (e) {
          toastMixin.fire({
            title: `Error updating NFT: ${e?.response?.data?.error}`,
            icon: 'error'
          })
        }
      },
      deleteOpeningData: async openingId => {
        try {
          if (!openingId) {
            return
          }

          const openingUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/opening/'
          const res = await axiosInstance.delete(openingUrl, {
            data: { id: openingId }
          })
          if (res?.status === 200) {
            toastMixin.fire({ title: 'Your have successfully deleted the item!' })
          }
        } catch (e) {
          toastMixin.fire({
            title: `Error deleting NFT: ${e?.response?.data?.error}`,
            icon: 'error'
          })
        }
      }
    }),
    {
      name: 'admin-storage',
      getStorage: () => localStorage
    }
  )
)

export default useAdminStore

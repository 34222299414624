import React, { useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'

const SearchInput = ({ handleChange, openingData }) => {
  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })
  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300)
  }, [openingData])

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Search Opening..."
        className="form-control search-input"
        onChange={debouncedResults}
      />
    </div>
  )
}

export default SearchInput

import React from 'react'
import { Button } from 'react-bootstrap'
import './CButton.scss'

const CButton = ({ w = 100, h = 40, onClick, secondary = false, disabled = false, children }) => {
  return (
    <div className={secondary ? 'button-container-wrapper secondary' : 'button-container-wrapper primary'} style={{ width: w, height: h }}>
      <div className='button-container-stroke'>
        <Button className='button-container' onClick={onClick} disabled={disabled}>
          {children}
        </Button>
      </div>
    </div>
  )
}

export default CButton

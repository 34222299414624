import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { io } from 'socket.io-client'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()
const socket = io(process.env.REACT_APP_BACKEND_ENDPOINT, { autoConnect: false })

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App socket={socket} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

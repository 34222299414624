import React from 'react'
import { Bar } from 'react-chartjs-2'
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto'
import { calculateAvgLast10Time, formatApiName } from './ChessApiAnalytics'

const ChessApiChart = ({ data }) => {
  const transformData = data => {
    const apiNames = Object.keys(data).map(formatApiName)

    const avgExecutionTimes = apiNames.map((name, index) => ({
      name,
      value: calculateAvgLast10Time(Object.values(data)[index].last10Time)
    }))

    const maxExecutionTimes = apiNames.map((name, index) => ({
      name,
      value: Object.values(data)[index].maxTime
    }))

    const totalCalls = apiNames.map((name, index) => ({
      name,
      value: Object.values(data)[index].count
    }))

    return { apiNames, avgExecutionTimes, maxExecutionTimes, totalCalls }
  }

  const { apiNames, avgExecutionTimes, maxExecutionTimes, totalCalls } =
    transformData(data)

  const normalizedData = {
    labels: apiNames,
    datasets: [
      {
        label: 'Avg Execution Time (last 10 calls)',
        data: avgExecutionTimes.map(item => item.value),
        backgroundColor: 'rgba(75, 192, 192, 1)'
      },
      {
        label: 'Max Execution Time (reset every month)',
        data: maxExecutionTimes.map(item => item.value),
        backgroundColor: 'rgba(153, 102, 255, 1)'
      },
      {
        label: 'Total Number of Calls (reset every month)',
        data: totalCalls.map(item => item.value),
        backgroundColor: 'rgba(255, 159, 64, 1)'
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || ''
            if (label) {
              label += ': '
            }
            if (
              context.dataset.label === 'Avg Execution Time (last 10 calls)'
            ) {
              label += avgExecutionTimes[context.dataIndex].value
            } else if (
              context.dataset.label === 'Max Execution Time (reset every month)'
            ) {
              label += maxExecutionTimes[context.dataIndex].value
            } else if (
              context.dataset.label ===
              'Total Number of Calls (reset every month)'
            ) {
              label += totalCalls[context.dataIndex].value
            }
            return label
          }
        }
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        type: 'logarithmic',
        display: false
      }
    },
    categoryPercentage: 0.8,
    barPercentage: 1
  }

  return (
    <div className="container mt-5">
      <div className='chart-container'>
        {normalizedData && <Bar data={normalizedData} options={options} />}
      </div>
    </div>
  )
}

export default ChessApiChart

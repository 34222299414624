import React, { useCallback } from 'react'
import './ChessTarget.scss'

const ChessTarget = ({ square, orientation, capture = false, onMouseDown, onTouchStart }) => {
  const squareToCoords = useCallback((square) => {
    // ASCII magic
    const file = parseInt(square[0].charCodeAt(0)) - 97
    const rank = 8 - (parseInt(square[1]))
    return { file: orientation === 'b' ? 7 - file : file, rank: orientation === 'b' ? 7 - rank : rank }
  }, [])
  return (
    <div className="chess-target"
      style={{ top: `${squareToCoords(square).rank * 12.5}%`, left: `${squareToCoords(square).file * 12.5}%` }}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
    >
      <div className={`${capture ? 'capture' : 'normal'}`} />
    </div>
  )
}

export default ChessTarget

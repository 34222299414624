import { useQuery } from 'react-query'
import axiosInstance from '../../network/axiosInstance'
import { toastMixin } from '../../utils/interact'

const getMarketplace = async (page = 0, limit = 12, filters) => {
  const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/marketplace'
  const statusValue = Object.keys(filters.status)
    .filter(obj => filters.status[obj])
    .toString()
  const colorValue = Object.keys(filters.color)
    .filter(obj => filters.color[obj])
    .map(obj => obj[0])
    .toString()
  const pClassValue = Object.keys(filters.pClass)
    .filter(obj => filters.pClass[obj])
    .toString()
  const params = {
    page,
    limit,
    sale: statusValue,
    color: colorValue,
    pClass: pClassValue,
    bCount: filters.bCount
  }

  return await axiosInstance.get(url, { params })
}
export const fetchMarketplace = (mPage, mLimit, mFilters) => {
  const { isLoading, data, isError, error, refetch } = useQuery(
    ['MARKETPLACE', { mPage, mLimit, mFilters }],
    async () => {
      const result = await getMarketplace(mPage, mLimit, mFilters)
      return result.data
    },
    {
      keepPreviousData: false,
      cacheTime: 0
    }
  )

  if (isError) {
    toastMixin.fire({ title: `Error fetching marketplace: ${error}`, icon: 'error' })
  }

  return { isLoading, data: data || {}, isError, error, refetch }
}

import React from 'react'
import { getPieceName } from '../../../../utils/pieces'
import useUserStore from '../../../../store/UserStore'
import './index.scss'
import Coin from '../../../../assets/icons/Coin.png'
import Close from '../../../../assets/icons/Close.png'
import { Col, Row } from 'react-bootstrap'

const PieceDetail = ({ togglePieceDetail, pieceDetail, inUseCollection, color }) => {
  const { useCollectionItem, removeCollectionItem } = useUserStore()

  const removeCollection = async () => {
    await removeCollectionItem(pieceDetail, color)
    togglePieceDetail()
  }

  const useCollection = async () => {
    await useCollectionItem(pieceDetail, color)
    togglePieceDetail()
  }

  return (
    <div className="main-container">
      <div className="title-main-container">
        <div className="title-background">
          <div className="title-text">Piece Detail</div>
        </div>
      </div>
      <div className="popup-details">
        <div className="close-container">
          <button className="close-background" onClick={togglePieceDetail}>
            <img src={Close} className="close-img" />
          </button>
        </div>
        <div className="detail-main-container">
          <div className="detail-title-container">
            <div className="detail-title-text">
              The {getPieceName(pieceDetail?.marketplace?.class)}
              <div className="bottom-underline"></div>
            </div>
          </div>
          <div className="detail-container d-flex mb-3">
            <div className="nft-container">
              <div className="nft-card-container">
                <div className="nft-image-container">
                  <img src={pieceDetail?.marketplace?.avatar} className="nft-image" />
                </div>
                <div className="piece-icon-container">
                  <img src={pieceDetail?.marketplace?.icon} className="piece-icon" />
                </div>
              </div>
            </div>
            <div className="card-detail-container">
              <div className="piece-text-container">
                <div className="piece-title">Rarity: </div>
                <div className="piece-text">Alpha</div>
              </div>
              <div className="piece-text-container">
                <div className="piece-title">Color: </div>
                <div className="piece-text">
                  {pieceDetail?.marketplace?.color === 'b' ? 'Black' : 'White'}
                </div>
              </div>
              <div className="piece-text-container">
                <div className="piece-title">Class: </div>
                <div className="piece-text">{getPieceName(pieceDetail?.marketplace?.class)}</div>
              </div>
              <div className="piece-text-container">
                <div className="piece-title">Tribe: </div>
                <div className="piece-text">Ghost</div>
              </div>
              <div className="piece-text-container">
                <div className="piece-title">Level: </div>
                <div className="piece-text">1</div>
              </div>
            </div>
          </div>
          {(pieceDetail?.inUse ||
            !inUseCollection[
              pieceDetail?.marketplace?.color + pieceDetail?.marketplace?.class
            ]) && (
            <Row className="button-main-container mb-3 mx-2">
              <Col className="offset-4 col-4 button-container">
                <div className="upgrade-btn-container">
                  <div className="upgrade-text">Upgrade</div>
                  <button className="upgrade-btn mt-2">
                    <img src={Coin} className="coin" />
                    <div className="amount-text">100</div>
                  </button>
                </div>
              </Col>
              <Col className="col-4">
                {pieceDetail?.inUse
                  ? (
                  <button onClick={removeCollection} className="btn-container remove-btn-container">
                    <div className="remove-text">Remove</div>
                  </button>
                    )
                  : (
                  <button onClick={useCollection} className="btn-container use-btn-container">
                    <div className="remove-text">Use</div>
                  </button>
                    )}
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  )
}

export default PieceDetail

import React from 'react'
import { Bishop, Knight, Pawn, Queen, Rook } from '../components/Pieces'

export const defaultPieces = (piece, size) => {
  switch (piece) {
    case 'p':
      return <Pawn height={size} width={size} />
    case 'r':
      return <Rook height={size} width={size} />
    case 'n':
      return <Knight height={size} width={size} />
    case 'b':
      return <Bishop height={size} width={size} />
    case 'q':
      return <Queen height={size} width={size} />
  }
}

export const getPiecePositions = (game, piece) => {
  return []
    .concat(...game.board())
    .map((p, index) => {
      if (p !== null && p.type === piece.type && p.color === piece.color) {
        return index
      }
      return null
    })
    .filter(Number.isInteger)
    .map(pieceIndex => {
      const row = 'abcdefgh'[pieceIndex % 8]
      const column = Math.ceil((64 - pieceIndex) / 8)
      return row + column
    })
}

export const getPieceName = type => {
  switch (type?.toLowerCase()) {
    case 'p':
      return 'Pawn'
    case 'r':
      return 'Rook'
    case 'n':
      return 'Knight'
    case 'b':
      return 'Bishop'
    case 'k':
      return 'King'
    case 'q':
      return 'Queen'
    default:
      return ''
  }
}

import React, { useState } from 'react'
import './PuzzleHeader.scss'
import GemIcon from '../../../assets/icons/Gem.png'
import Logo from '../../../assets/img/chessx-logo-white.png'
import RankingIcon from '../../../assets/icons/Ranking.png'
import useUserStore from '../../../store/UserStore'
import useGameStore from '../../../store/GameStore'
import { Modal } from 'react-bootstrap'
import LoginComponent from '../../Login/LoginComponent'
import RegistrationComponent from '../../Login/RegistrationComponent/RegistrationComponent'
import ForgotPasswordComponent from '../../Login/ForgotPasswordComponent/ForgotPasswordComponent'
import Swal from 'sweetalert2'
import CButton from '../../Common/CButton'

export const PuzzleHeader = () => {
  const { socket } = useGameStore()
  const { user, setUser, setUserId, setToken, setGuestId, rank, fetchRank, level, completed } = useUserStore()
  const [loginVisible, setLoginVisible] = useState(false)
  const [registerVisible, setRegisterVisible] = useState(false)
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false)
  const gemsBalance = user ? user.gems : '0.00'
  const zero = num => (num < 10 ? `0${num}` : num)
  const onSuccess = async obj => {
    setLoginVisible(false)
    setRegisterVisible(false)
    setUserId(obj._id)
    setGuestId(null)
    setUser(obj)
    setToken(obj.accessToken)
    fetchRank()
    try {
      if (typeof socket !== 'undefined') {
        socket.disconnect()
        socket.auth = { userId: obj._id }
        socket.connect()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There is a problem in Socket connection'
        })
      }
    } catch (err) {
      console.log('err---', err)
    }
  }

  const toggleLogin = async () => {
    setLoginVisible(state => !state)
  }
  const toggleRegister = async () => {
    setRegisterVisible(state => !state)
  }
  const toggleForgotPassword = async () => {
    setForgotPasswordVisible(state => !state)
  }
  return (
    <>
      <div className="puzzle-page-header">
        <img className="logo" src={Logo}/>
        {user
          ? <div className="d-flex justify-content-end align-items-center ">
            <div className="xp-container">
              <div className="star-container">
                <div className='level-text'>{zero(level)}</div>
              </div>
              <div className='progress-bar' style={{ width: completed }} />
            </div>
            <div className="user-detail ms-3 ms-md-4 ms-lg-5">
              <span className="span-plus d-flex align-items-center">
                <img src={GemIcon} alt="eye-icon" className="eye-icon img-fluid" />
                {gemsBalance}
              </span>
            </div>
            {rank && (
              <div className="user-detail ms-3 ms-md-4 ms-lg-5">
                <span className="span-plus d-flex align-items-center">
                  <img src={RankingIcon} alt="icon-rank" className="eye-icon img-fluid" />
                  {rank}
                </span>
              </div>
            )}
          </div>
          : <div className='d-flex justify-content-end align-items-center'>
            <CButton onClick={toggleLogin}>Login</CButton>
          </div>}
      </div>

      <Modal centered show={loginVisible} onHide={toggleLogin}>
        <LoginComponent
          onSuccess={onSuccess}
          toggleLogin={toggleLogin}
          toggleRegister={toggleRegister}
          toggleForgotPassword={toggleForgotPassword}
        />
      </Modal>
      <Modal centered show={registerVisible} onHide={toggleRegister}>
        <RegistrationComponent
          onSuccess={onSuccess}
          toggleLogin={toggleLogin}
          toggleRegister={toggleRegister}
        />
      </Modal>
      <Modal centered show={forgotPasswordVisible} onHide={toggleForgotPassword}>
        <ForgotPasswordComponent
          toggleForgotPassword={toggleForgotPassword}
          toggleLogin={toggleLogin}
        />
      </Modal>
    </>
  )
}

export default PuzzleHeader

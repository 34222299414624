import { useQuery } from 'react-query'
import axiosInstance from '../../network/axiosInstance'
import { toastMixin } from '../../utils/interact'

export const fetchChessApi = () => {
  const { isLoading, data, isError, error, refetch } = useQuery(
    'CHESS_API',
    async () => {
      const url =
        process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/api-analytics'
      const result = await axiosInstance.get(url)
      if (result.data?.status === 200) {
        return result.data?.data?.data
      }
    },
    {
      keepPreviousData: false,
      cacheTime: 0
    }
  )

  if (isError) {
    toastMixin.fire({
      title: `Error fetching chess api: ${error}`,
      icon: 'error'
    })
  }

  return { isLoading, data: data || null, isError, error, refetch }
}

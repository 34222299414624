import axios from 'axios'
import useUserStore from '../store/UserStore'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  headers: {
    Accept: 'application/json'
  }
})

let isTokenRefreshing = false
let requestCaching = []

axiosInstance.interceptors.request.use(
  config => {
    const token = useUserStore.getState().token
    if (token) {
      config.headers.common = { Authorization: token }
    }
    return config
  },
  error => {
    Promise.reject(error.response || error.message)
  }
)

axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const status = error?.response?.status || 0
    const resBaseURL = error?.response?.config?.baseURL
    if (resBaseURL === process.env.REACT_APP_BACKEND_ENDPOINT && status === 401) {
      const token = useUserStore.getState().token
      const refreshToken = useUserStore.getState().refreshToken
      if (token && refreshToken && error?.response?.data?.expired) {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true
          updateToken(refreshToken, (_, err) => {
            if (err) {
              localStorage.clear()
            }
            setTimeout(() => {
              recallCachedAPIs(err)
              requestCaching = []
              isTokenRefreshing = false
            }, 1000) // To handle parallel API Calls
          })
        }

        return new Promise((resolve, reject) => {
          subscribeToken((token, err) => {
            if (err) return reject(error)
            error.config.headers.Authorization = token
            resolve(axios(error.config))
          })
        })
      }
      localStorage.clear()
      window.location.assign('/')
    }
    return Promise.reject(error)
  }
)
const subscribeToken = cb => {
  requestCaching.push(cb)
}

const recallCachedAPIs = error => {
  const token = useUserStore.getState().token
  requestCaching.forEach(cb => cb(token, error))
}

const updateToken = async (refreshToken, callback) => {
  try {
    const response = await axiosInstance.post('api/v1/user/refresh-token', { refreshToken })
    const { data } = response.data
    if (data?.token && data?.refreshToken) {
      useUserStore.getState().setToken(data?.token)
      useUserStore.getState().setRefreshToken(data?.refreshToken)
      callback(null, false)
      return
    }
    callback(null, true)
  } catch (err) {
    callback(null, true)
  }
}

export default axiosInstance

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Pagination, Row, Table } from 'react-bootstrap'
import { USER_PAGE_LIMIT } from '../../constants'
import useAdminStore from '../../store/AdminStore'
import './AdminUsers.scss'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../network/axiosInstance'
import { toastErrorMixin, toastMixin } from '../../utils/interact'
import RegistrationComponent from '../../components/Login/RegistrationComponent'

const AdminUsers = props => {
  const [page, setPage] = useState(0)
  const [pagesArray, setPagesArray] = useState([])
  const { fetchUsers } = useAdminStore()
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState(null)
  const [syncing, setSyncing] = useState(false)
  const [registerVisible, setRegisterVisible] = useState(false)

  const tableHead = [
    { id: 'userName', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Role' },
    { id: 'lastLogin', label: 'Last Sign In' },
    { id: 'edit', label: '' },
    { id: 'destroy', label: '' },
    { id: 'deactivate', label: '' }
  ]
  const getUser = async () => {
    try {
      setIsLoading(true)
      const params = {
        page,
        limit: USER_PAGE_LIMIT
      }
      const response = await fetchUsers(params)
      setUserData(response)
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }
  const navigate = useNavigate()

  const onEditClick = user => {
    navigate('/admin/users/detail', { state: user })
  }

  const onDeactivateClick = async user => {
    try {
      const params = { isActive: !user.isActive, userId: user._id }
      const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/admin/updateUser'
      await axiosInstance.put(url, params)
      getUser()
    } catch (err) {
      toastErrorMixin.fire({
        title: 'Error updating user.'
      })
    }
  }

  const onDestroyClick = async user => {
    try {
      const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/admin/destroyUser'
      await axiosInstance.delete(url, { params: { userId: user._id } })
      getUser()
    } catch (err) {
      toastErrorMixin.fire({
        title: 'Error destroying user.'
      })
    }
  }

  const syncCollections = async user => {
    try {
      setSyncing(true)
      const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/collection/admin/syncCollection'
      const res = await axiosInstance.post(url)
      if (res.status === 200) {
        toastMixin.fire({
          title: 'Sync Successful'
        })
      }
    } catch (err) {
      toastErrorMixin.fire({
        title: 'Error syncing user collections.'
      })
    } finally {
      setSyncing(false)
    }
  }

  const onSuccess = async obj => {
    setRegisterVisible(false)
    getUser()
  }

  useEffect(() => {
    getUser()
  }, [page])

  useEffect(() => {
    if (Array.isArray(userData?.data)) {
      const pagesArray = [...Array(Math.ceil(userData.totalCount / USER_PAGE_LIMIT) || 1).keys()]
      setPagesArray(pagesArray)
    }
  }, [userData?.data?.length])

  const toggleRegister = async () => {
    setRegisterVisible(state => !state)
  }

  return (
    <Container className="admin-user-container">
      <div className="title">
        Users
        <div className="d-flex">
          <Button disabled={syncing} className="edit-btn mx-4" onClick={toggleRegister}>
            {'New User'}
          </Button>
          <Button disabled={syncing} className="edit-btn" onClick={syncCollections}>
            {syncing ? 'Syncing' : 'Sync Collections'}
          </Button>
        </div>
      </div>
      <div className="main-detail-container">
        <Row className="mt-4" style={{ height: '100%' }}>
          {isLoading && <div>Loading...</div>}
          {userData?.data?.length
            ? (
                <Col xs={12}>
                  <Table responsive bordered hover>
                    <thead>
                      <tr>
                        <th className="table-cell">#</th>
                        {tableHead.map((ele, index) => (
                          <th key={index} className="table-cell">
                            {ele.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.data.map((user, index) => {
                        return (
                          <tr key={index} className={`${!user.isActive && 'red-row'}`}>
                            <td className="table-cell pointer-cursor" onClick={() => onEditClick(user)}>
                              {page * USER_PAGE_LIMIT + (index + 1)}
                            </td>
                            {tableHead.map((ele, ind) => {
                              if (ele.id === 'edit') {
                                return (
                                  <td key={ind} className="table-cell">
                                    <Button className="edit-btn" onClick={() => onEditClick(user)}>
                                      Edit
                                    </Button>
                                  </td>
                                )
                              }
                              if (ele.id === 'destroy') {
                                return (
                                  <td
                                    key={ind}
                                    className="table-cell"
                                    onClick={() => onDeactivateClick(user)}
                                  >
                                    <Button
                                      className={`${user.isActive ? 'destroy-btn' : 'activate-btn'}`}
                                    >
                                      {user.isActive ? 'Deactivate' : 'Activate'}
                                    </Button>
                                  </td>
                                )
                              }
                              if (ele.id === 'deactivate') {
                                return (
                                  <td
                                    key={ind}
                                    className="table-cell"
                                    onClick={() => onDestroyClick(user)}
                                  >
                                    <Button
                                      className={'destroy-btn'}
                                    >
                                      {'Destroy user'}
                                    </Button>
                                  </td>
                                )
                              }
                              if (ele.id === 'lastLogin') {
                                return (
                                  <td
                                    key={ind}
                                    className="table-cell pointer-cursor"
                                    onClick={() => onEditClick(user)}
                                  >
                                    {Object.keys(user).includes('lastLogin')
                                      ? DateTime.fromJSDate(new Date(user[ele.id])).toFormat(
                                        'yyyy/MM/dd'
                                      )
                                      : '-'}
                                  </td>
                                )
                              }
                              if (ele.id === 'role') {
                                return (
                                  <td
                                    key={ind}
                                    className="table-cell role-cell pointer-cursor"
                                    onClick={() => onEditClick(user)}
                                  >
                                    {user[ele.id]}
                                  </td>
                                )
                              }
                              return (
                                <td
                                  key={ind}
                                  className="table-cell pointer-cursor"
                                  onClick={() => onEditClick(user)}
                                >
                                  {user[ele.id]}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Col>
              )
            : null}
          <Pagination>
            {pagesArray.map((obj, index) => (
              <Pagination.Item key={index} onClick={() => setPage(obj)} active={obj === page}>
                {obj + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Row>
      </div>
      <Modal centered show={registerVisible} onHide={toggleRegister}>
        <RegistrationComponent
          onSuccess={onSuccess}
          isFromUserTable
          toggleRegister={toggleRegister}
        />
      </Modal>
    </Container>
  )
}
export default AdminUsers

/* eslint-disable multiline-ternary */

import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import useAdminStore from '../../../store/AdminStore'

const OpeningDBTable = props => {
  const { openingData, isLoading } = props

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Container className="container">
      <div className="card">
        <div className="card-body py-2">
          {openingData && openingData.map(node => <TreeNode node={node} key={node.key} />)}
        </div>
      </div>
    </Container>
  )
}

const TreeNode = ({ node }) => {
  const { children, name, fen, pgn } = node
  const [showChildren, setShowChildren] = useState(false)
  const [editableData, setEditData] = useState(false)
  const { updateOpeningData } = useAdminStore()

  const handleClick = e => {
    setShowChildren(showChildren => !showChildren)
  }

  const onEditClick = e => {
    e.stopPropagation()
    setEditData(s => (s ? null : node))
  }

  const onSaveClick = e => {
    e.stopPropagation()
    updateOpeningData({
      _id: node._id,
      name: editableData.name,
      variation: editableData?.variation
    })
    node.name = editableData.name
    node.variation = editableData?.variation
    setEditData(s => (s ? null : node))
  }

  const handleEdit = (id, value) => {
    setEditData(s => ({ ...s, [id]: value }))
  }

  const renderEditContainer = () => {
    return (
      <div>
        <div className="d-flex align-items-center">
          <div className="me-2">Name</div>
          <input
            type="text"
            value={editableData.name}
            className="form-control edit-input"
            onChange={e => handleEdit('name', e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center mt-2">
          <div className="me-2">Variation</div>
          <input
            type="text"
            value={editableData?.variation}
            className="form-control edit-input"
            onChange={e => handleEdit('variation', e.target.value)}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="border-bottom py-2">
      <div className="d-flex align-items-center justify-content-between" onClick={handleClick}>
        <div className="d-flex align-items-center">
          <div className="align-items-center" style={{ width: '15px' }}>
            {children && (
              <svg
                width="10px"
                height="10px"
                viewBox="0 0 1024 1024"
                className="icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M917.333333 364.8L851.2 298.666667 512 637.866667 172.8 298.666667 106.666667 364.8 512 768z"
                  fill="#000000"
                />
              </svg>
            )}
          </div>
          <div className="align-items-center mx-3">
            <div className="ml-2 font-weight-bold">
              {editableData
                ? renderEditContainer()
                : `${name} ${node?.variation ? `(${node.variation})` : ''}`}
            </div>
            <div className="ml-5" style={{ fontSize: '12px' }}>
              {fen}
            </div>
            <div className="ml-5" style={{ fontSize: '12px' }}>
              {pgn}
            </div>
          </div>
        </div>
        {editableData ? (
          <div className="">
            <div className="edit-btn-container">
              <Button onClick={onEditClick}>Cancel</Button>
              <Button className="ms-2" onClick={onSaveClick}>
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div className="">
            <Button onClick={onEditClick}>Edit</Button>
          </div>
        )}
      </div>
      {children && showChildren && (
        <div className="mt-2">
          <OpeningDBTable openingData={children} />
        </div>
      )}
    </div>
  )
}
export default OpeningDBTable

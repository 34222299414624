import React, { useState } from 'react'
import { Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import './RegistrationComponent.scss'
import { toastErrorMixin } from '../../../utils/interact'
import { Constants } from '../../../utils/packet'
import { validateEmail } from '../../../libraries/common'
import useUserStore from '../../../store/UserStore'
import axiosInstance from '../../../network/axiosInstance'
import { EmailIcon, EyeIcon, EyeClosedIcon, LockIcon, UserIcon } from '../../../assets/icons'

const RegistrationComponent = ({ onSuccess, toggleRegister, isFromUserTable = false }) => {
  const { walletAddress } = useUserStore()
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
    // Check and see if errors exist, and remove them from the error object:
    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      })
    }
  }

  const findFormErrors = () => {
    const { email, password, password2, userName } = form
    const newErrors = {}
    if (!email || email === '') newErrors.email = 'Email cannot be blank!'
    else if (!validateEmail(email)) newErrors.email = 'Email should be valid!'
    if (!userName || userName === '') newErrors.userName = 'Username cannot be blank!'
    if (!password || password === '') newErrors.password = 'Password cannot be blank!'
    else if (password.length < 8) {
      newErrors.password = 'Password should be at least 8 character long!'
    }
    if (!password2) newErrors.password2 = 'Password cannot be blank!'
    else if (password !== password2) {
      newErrors.password2 = 'Password does not match!'
    }

    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const newErrors = findFormErrors()
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setSubmitting(true)
      try {
        const params = walletAddress
          ? {
              ...form,
              walletId: walletAddress
            }
          : form
        const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/signup'
        const res = await axiosInstance.post(url, params)
        if (res && res.data && res.data.status === 200) {
          localStorage.setItem(Constants.USER, JSON.stringify(res.data.data))
          onSuccess(res.data.data)
        } else {
          toastErrorMixin.fire({
            title: res.data.message
          })
        }
      } catch (err) {
        console.log('error', err)
        toastErrorMixin.fire({
          title: 'Error logging in.'
        })
      } finally {
        setSubmitting(false)
      }
    }
  }

  const [passwordShown, setPasswordShown] = useState(false)
  const togglePass = () => {
    setPasswordShown(state => !state)
  }
  const [rePasswordShown, setRePasswordShown] = useState(false)
  const toggleRePass = () => {
    setRePasswordShown(state => !state)
  }
  return (
    <Row className="quick-match-area">
      <div className="register-container">
        <h1 className="title">{isFromUserTable ? 'CREATE NEW USER' : 'CREATE ACCOUNT'}</h1>
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom02" className="form-container">
              <InputGroup hasValidation={true} className={`${errors.email ? 'is-invalid' : ''}`}>
                <InputGroup.Text>
                  <EmailIcon height={20} width={20} fill="#737171" />
                </InputGroup.Text>
                <Form.Control
                  className="inputboxEmail"
                  required
                  type="email"
                  placeholder="Email"
                  onChange={e => setField('email', e.target.value)}
                  isInvalid={!!errors.email}
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom03">
              <InputGroup hasValidation={true} className={`${errors.userName ? 'is-invalid' : ''}`}>
                <InputGroup.Text>
                  <UserIcon height={20} width={20} />
                </InputGroup.Text>
                <Form.Control
                  className="inputboxEmail"
                  required
                  type="text"
                  placeholder="Enter your username"
                  onChange={e => setField('userName', e.target.value)}
                  isInvalid={!!errors.userName}
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors.userName}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom06">
              <InputGroup hasValidation={true} className={`${errors.password ? 'is-invalid' : ''}`}>
                <InputGroup.Text>
                  <LockIcon height={20} width={20} />
                </InputGroup.Text>
                <Form.Control
                  className="form-password"
                  type={passwordShown ? 'text' : 'password'}
                  placeholder="Password"
                  required
                  minLength={8}
                  onChange={e => setField('password', e.target.value)}
                  isInvalid={!!errors.password}
                />
                <InputGroup.Text>
                  <i onClick={togglePass}>
                    {passwordShown
                      ? <EyeClosedIcon height={20} width={20} />
                      : <EyeIcon height={20} width={20} />
                    }
                  </i>
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom05">
              <InputGroup hasValidation={true} className={`${errors.password2 ? 'is-invalid' : ''}`}>
                <InputGroup.Text>
                  <LockIcon height={20} width={20} />
                </InputGroup.Text>
                <Form.Control
                  className="form-password"
                  type={rePasswordShown ? 'text' : 'password'}
                  placeholder="Re-enter Password"
                  required
                  minLength={8}
                  onChange={e => setField('password2', e.target.value)}
                  isInvalid={!!errors.password2}
                />
                <InputGroup.Text>
                  <i onClick={toggleRePass}>
                    {rePasswordShown
                      ? <EyeClosedIcon height={20} width={20} />
                      : <EyeIcon height={20} width={20} />
                    }
                  </i>
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors.password2}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 mt-5">
            <Col>
              <div className="button-parent">
                <Button disabled={submitting} type="submit" className="register-btn">
                {submitting && <Spinner size='sm' animation="border" />}
                {!submitting && !walletAddress && 'CREATE'}
                {!submitting && walletAddress && 'CREATE email'}
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <div className="button-parent">
                <Button
                  className="register-btn"
                  onClick={toggleRegister}
                >
                  CANCEL
                </Button>
              </div>
            </Col>
          </Row>
          {!walletAddress && !isFromUserTable && (
            <Row className="mb-3 mt-4">
              <Col className="already-account">Terms of Use | Privacy Policy</Col>
            </Row>
          )}
        </Form>
      </div>
    </Row>
  )
}

export default RegistrationComponent

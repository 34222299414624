import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Modal, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Chess } from 'chess.js'
import useGameStore from '../../store/GameStore'
import useUserStore from '../../store/UserStore'
import socketEvents from '../../utils/packet'
import { LoseGameModal, WinGameModal } from '../../components/Common/Modals'
import AdsComponent from '../../components/Common/AdsComponent'
import './GameBoardAI.scss'
import { defaultPieces } from '../../utils/pieces'
import { GEM_TAKEBACK_AMOUNT, PIECES_POINTS } from '../../constants'
import GuestRegistration from '../../components/Common/Modals/GuestRegistration'
import AIImg from '../../assets/img/ai.png'
import UserImg from '../../assets/img/user.png'
import treeLeft from '../../assets/img/tree-left.png'
import treeRight from '../../assets/img/tree-right.png'
import treeTop from '../../assets/img/tree-top.png'
import ActionButton from '../../components/GameBoardAI/ActionButton'
import { chessPieces } from '../../utils/chessPieces'
import { wPNft } from '../../assets/nft-pieces'
import ExitModal from '../../components/Common/Modals/ExitGame'
import ChessBoard from '../../components/GameBoardAI/ChessBoard'

const game = new Chess()

const GameBoard = () => {
  const chessboardRef = useRef()
  const isFinished = useRef()

  const { socket, isConnected, gameInfo, setIsConnected } = useGameStore()
  const { userId, guestId, walletAddress, fetchUserCollection, user, setUser, rank } =
    useUserStore()

  const [fen, setFen] = useState()
  const [mCount, setMCount] = useState(0)
  const [oCount, setOCount] = useState(0)
  const [isUserWon, setIsUserWon] = useState(false)
  const [isUserLost, setIsUserLost] = useState(false)
  const [chessPiecesImg, setChessPiecesImg] = useState(wPNft)
  const [capturedPieces, setCapturedPieces] = useState([])
  const [piecesPoint, setPiecesPoint] = useState({})
  // const [isNFT3dView] = useState(false)
  const [chessBoardRender, setChessBoardRender] = useState(true)
  const [isBackButtonEnabled, setBackButtonEnabled] = useState(false)
  const [isExitVisible, setIsExitVisible] = useState(false)
  const [orientation, setOrientation] = useState('white')
  const [otherusername, setOtherusername] = useState('')
  const [otherrank, setOtherrank] = useState('')
  const [otherid, setOtherid] = useState('')
  const [turn, setTurn] = useState('w')

  const history = game.history()
  const navigate = useNavigate()

  const toggleExit = async () => {
    setIsExitVisible(state => !state)
  }

  useEffect(() => {
    setBackButtonEnabled(history.length >= 1 && user?.gems >= GEM_TAKEBACK_AMOUNT)
  }, [history.length, user])

  useEffect(async () => {
    if (!gameInfo.isGuest) await fetchUserCollection()
  }, [])

  useEffect(() => {
    if (chessBoardRender) {
      setChessBoardRender(false)
      setTimeout(() => setChessBoardRender(true), 0)
    }
  }, []) // [isNFT3dView])

  const onFinishGame = useCallback(() => {
    setIsUserLost(false)
    setIsUserWon(false)
    setIsExitVisible(false)
    setIsConnected(false)
    navigate(-1)
  }, [userId, walletAddress])

  const opponentMove = ({ from, to }) => {
    const capturePiece = game.get(to)
    if (capturePiece) {
      setCapturedPieces(obj => [...obj, capturePiece])
    }
    const move = game.move({
      from,
      to,
      promotion: 'q'
    })
    if (!move) return
    checkGameState()
    setFen(game.fen())
    chessboardRef?.current.highlightLastMoves({
      sourceSquare: move?.from,
      targetSquare: move?.to
    })
    const Pieces = chessPieces({ color: move?.color, type: move?.piece })
    setChessPiecesImg(Pieces)
  }

  useEffect(() => {
    if ((!walletAddress && !userId && !guestId) || typeof socket === 'undefined') {
      setIsConnected(false)
      navigate('/')
    }
    if (!isConnected) navigate('/')

    const isWhite = gameInfo?.creator_socket_id === socket?.id || gameInfo?.socketId === socket?.id

    game.reset()
    setFen(game.fen())
    setTurn(isWhite ? 'w' : 'b')
    setOrientation(isWhite ? 'white' : 'black')
    setOtherusername((isWhite ? gameInfo?.connector_user_name : gameInfo.creator_user_name) || 'AI')
    setOtherrank((isWhite ? gameInfo.connector_rank : gameInfo.creator_rank) || 1200)

    if (typeof socket !== 'undefined') {
      setOtherid(isWhite ? gameInfo.connector_socket_id : gameInfo.creator_socket_id)

      socket.on(socketEvents.SC_AIMove, res => {
        if (res.game_id === gameInfo.game_id) {
          opponentMove({ from: res.from, to: res.to })
        }
      })

      socket.on(socketEvents.SC_Move, res => {
        if (res.game_id === gameInfo.game_id) {
          opponentMove({ from: res.sourceSquare, to: res.targetSquare })
        }
      })

      socket.on(socketEvents.SC_Count, ({ params, gameId }) => {
        const { w, b } = params
        if (gameId === gameInfo.game_id) {
          if (isWhite) {
            setMCount(w)
            setOCount(b)
          } else {
            setMCount(b)
            setOCount(w)
          }
        }
      })

      socket.on(socketEvents.SC_AILost, res => {
        if (res.gameInfo.game_id === gameInfo.game_id) {
          setIsUserWon(true)
          isFinished.current = true
        }
      })

      socket.on(socketEvents.SC_AIWon, res => {
        if (res.gameInfo.game_id === gameInfo.game_id) {
          setIsUserLost(true)
          isFinished.current = true
        }
      })

      socket.on(socketEvents.SC_GameFinished, ({ winnerSocketId }) => {
        if (winnerSocketId === socket.id) {
          setIsUserWon(true)
        } else {
          setIsUserLost(true)
        }
        isFinished.current = true
      })

      socket.on(socketEvents.SC_AIUPDATEDFEN, res => {
        if (res.game_id === gameInfo.game_id) {
          if (res?.user) {
            setBackButtonEnabled(true)
            undoStep()
            undoStep()
            setFen(game.fen())
            setUser(res?.user)
          }
        }
      })

      socket.on(socketEvents.SC_UPDATEDFEN, res => {
        if (res.game_id === gameInfo.game_id) {
          chessboardRef?.current.removeLastHighlight()
          chessboardRef?.current.resetSquares()
          setBackButtonEnabled(true)
          for (let i = 0; i < res.noUndoStep; i++) {
            undoStep()
          }
          setFen(game.fen())
          if (res?.user) setUser(res?.user)
        }
      })
    }

    if (typeof socket !== 'undefined') {
      return () => {
        setIsConnected(false)
        if (gameInfo) {
          socket.emit(socketEvents.LEAVEROOM, gameInfo)
        }

        socket.off(socketEvents.SC_AIMove)
        socket.off(socketEvents.SC_Count)
        socket.off(socketEvents.SC_AIWon)
        socket.off(socketEvents.SC_AILost)
        socket.off(socketEvents.SC_AIUPDATEDFEN)
        socket.off(socketEvents.CS_AIDraw)

        socket.off(socketEvents.SC_Move)
        socket.off(socketEvents.SC_GameFinished)
        socket.off(socketEvents.SC_UPDATEDFEN)
        socket.off(socketEvents.CS_Draw)
      }
    }
  }, [])

  const undoStep = () => {
    const move = game.undo()
    const capturePiece = game.get(move?.to)
    if (capturePiece) {
      setCapturedPieces(obj => {
        const captureIndex = obj.findIndex(
          ele => ele.type === capturePiece.type && ele.color === capturePiece.color
        )
        if (captureIndex !== -1) {
          return [...obj.slice(0, captureIndex), ...obj.slice(captureIndex + 1)]
        }
        return obj
      })
    }
  }

  const onTakeBack = () => {
    if (history.length >= 1 && gameInfo) {
      setBackButtonEnabled(false)
      chessboardRef?.current.removeLastHighlight()
      chessboardRef?.current.resetSquares()

      if (gameInfo?.isAIGame) {
        socket.emit(socketEvents.CS_AITAKEBACK, {
          game_id: gameInfo.game_id,
          fen: game.fen()
        })
      } else {
        const isMyTurn = game.turn() === turn
        const userturn = game.turn()
        socket.emit(socketEvents.CS_TAKEBACK, {
          game_id: gameInfo.game_id,
          fen: game.fen(),
          userturn,
          isMyTurn,
          availableUndoMoves: history.length,
          otherid
        })
      }
    }
  }

  const checkGameState = () => {
    if (game.isGameOver()) {
      if (game.isDraw()) {
        if (orientation) {
          if (gameInfo?.isAIGame) socket.emit(socketEvents.CS_AIDraw, { game_id: gameInfo.game_id })
          else socket.emit(socketEvents.CS_Draw, { game_id: gameInfo.game_id })
        }
        setIsUserLost(true)
        isFinished.current = true
      }
    }
  }

  const zero = num => (num < 10 ? `0${num}` : num)

  const getMSString = value => `${zero(Math.floor(value / 60))} : ${zero(value % 60)}`

  useEffect(() => {
    const counts = { b: 0, w: 0 }
    const pieces = { b: [], w: [] }
    capturedPieces.forEach(ele => {
      counts[ele.color] += PIECES_POINTS[ele.type]
      pieces[ele.color].push(ele.type)
    })
    for (let i = 0; i < pieces.b.length; i++) {
      const wIndex = pieces.w.indexOf(pieces.b[i])
      if (wIndex >= 0) {
        pieces.w.splice(wIndex, 1)
        pieces.b.splice(i, 1)
        i--
      }
    }
    const difference = { b: '', w: '' }
    if (counts.b - counts.w > 0) difference.b = `+${counts.b - counts.w}`
    else if (counts.w - counts.b > 0) difference.w = `+${counts.w - counts.b}`

    const svgPieces = {
      b: pieces.b.map(obj => defaultPieces(obj, window.innerWidth < 1024 ? 15 : 25)),
      w: pieces.w.map(obj => defaultPieces(obj, window.innerWidth < 1024 ? 15 : 25))
    }
    if (counts.w - counts.b === 0) setPiecesPoint({})
    else setPiecesPoint({ pieces: svgPieces, difference })
  }, [capturedPieces])

  const onParentDrop = ({ sourceSquare, targetSquare }) => {
    const capturePiece = game.get(targetSquare)
    if (capturePiece) {
      setCapturedPieces(obj => [...obj, capturePiece])
    }
    const move = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: 'q'
    })
    if (move === null) return
    checkGameState()
    setFen(game.fen())

    const gameId = gameInfo.game_id
    if (gameInfo?.isAIGame) {
      socket.emit(socketEvents.CS_AIMove, {
        game_id: gameId,
        fen: game.fen()
      })
    } else {
      const userturn = game.turn()
      const gameId = gameInfo.game_id
      socket.emit(socketEvents.CS_Move, {
        sourceSquare,
        targetSquare,
        userturn,
        otherid,
        game_id: gameId,
        fen: game.fen()
      })
    }

    const Pieces = chessPieces({ color: move?.color, type: move?.piece })
    setChessPiecesImg(Pieces)
  }

  const formatOpponentsPoints = () => {
    if (piecesPoint?.difference && orientation === 'white') {
      return (
        <>
          <div className="pieces-icon mb-lg-0 mb-2">{piecesPoint?.pieces?.w}</div>
          <div className="pieces-text text-white">{piecesPoint?.difference?.w}</div>
        </>
      )
    } else if (piecesPoint?.difference && orientation === 'black') {
      return (
        <>
          <div className="pieces-icon mb-lg-0 mb-2">{piecesPoint?.pieces?.b}</div>
          <div className="pieces-text text-white">{piecesPoint?.difference?.b}</div>
        </>
      )
    }
    return null
  }

  const formatYourPoints = () => {
    if (piecesPoint?.difference && orientation === 'white') {
      return (
        <>
          <div className="pieces-icon mb-lg-0 mb-2">{piecesPoint?.pieces?.b}</div>
          <div className="pieces-text text-white">{piecesPoint?.difference?.b}</div>
        </>
      )
    } else if (piecesPoint?.difference && orientation === 'black') {
      return (
        <>
          <div className="pieces-icon mb-lg-0 mb-2">{piecesPoint?.pieces?.w}</div>
          <div className="pieces-text text-white">{piecesPoint?.difference?.w}</div>
        </>
      )
    }
    return null
  }

  // const handleNFTCheck = e => {
  //   setIsNFT3dView(e.target.checked)
  // }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [])

  const onBackButtonEvent = e => {
    if (!isFinished.current) {
      e.preventDefault()
      toggleExit()
    } else {
      navigate(-1)
    }
  }

  const onClickNo = () => {
    window.history.pushState(null, null, window.location.pathname)
    isFinished.current = false
    toggleExit()
  }

  const onResign = () => {
    isFinished.current = true
    toggleExit()
  }

  const isLessThan1024 = window.innerWidth < 1024
  return (
    <div className="game-board-pvai">
      <div className="container py-lg-4 pb-2 px-3">
        <div className={`${!isLessThan1024 && 'row h-100'} `}>
          <div className={`${isLessThan1024 ? 'col-12 w-100' : 'col-2 my-auto'}`}>
            {isLessThan1024
              ? <img src={treeTop} className="imageTop w-100" />
              : <img src={treeLeft} className="w-75" />
            }
          </div>
          <div
            className={`${isLessThan1024 ? 'w-100 col-12' : 'col-6 d-flex justify-content-center'}`}
          >
            <Col
              className={`${isLessThan1024 ? 'mt-3' : 'marginLeftBoardArea'} board-area`}
              lg={12}
            >
              <div
                className={`${
                  isLessThan1024 ? 'col-lg-7 col-md-9 col-12' : 'col-10'
                } mx-auto d-flex justify-content-between align-items-center mb-1`}
              >
                <div className="d-flex">
                  <div className="profile-img">
                    <img src={AIImg} className="img-fluid" />
                  </div>
                  <div className="">
                    <div className="board-area-rank ms-2">{otherrank}</div>
                    <div className="board-area-name ms-2">{otherusername}</div>
                    <div className="pieces-container d-flex align-items-center ms-1">
                      {formatOpponentsPoints()}
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="time-text">{getMSString(oCount)}</p>
                </div>
              </div>
              <div className="chessboard p-lg-3 p-md-3 p-2">
                {chessBoardRender && (
                  <ChessBoard
                    ref={chessboardRef}
                    position={fen}
                    game={game}
                    orientation={orientation}
                    turn={turn}
                    onParentDrop={onParentDrop}
                  />
                )}
              </div>
              <div className="d-flex flex-column">
                <div
                  className={`${
                    isLessThan1024 ? 'col-lg-7 col-md-9 col-12 order-1' : 'col-10'
                  } mx-auto d-flex justify-content-between align-items-center mt-2`}
                >
                  <div className="d-flex">
                    <div className="profile-img">
                      <img src={UserImg} className="img-fluid" />
                    </div>
                    <div className="">
                      <div className="board-area-rank ms-2">{rank || 1200}</div>
                      <div className="board-area-name ms-2">
                        {user?.userName || userId || guestId}
                      </div>
                      <div className="pieces-container d-flex align-items-center ms-1">
                        {formatYourPoints()}
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <p className="time-text">{getMSString(mCount)}</p>
                  </div>
                </div>
                {isLessThan1024 && (
                  <div className="col-lg-7 col-md-9 col-12 order-0 mx-auto">
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <ActionButton
                        onTakeBack={onTakeBack}
                        isBackButtonEnabled={isBackButtonEnabled}
                        onResign={onResign}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </div>
          {!isLessThan1024 && (
            <>
              <div className="col-2 treeMargin my-auto">
                <img src={treeRight} className="w-75" />
              </div>
              <div className="col-3 ms-auto pt-5 pe-0">
                <Col lg={12} md={12}>
                  <div className={`${!isLessThan1024 && 'handle-area px-3 pb-5'}`}>
                    <div className="handle-container p-3">
                      <img id="myImg" src={chessPiecesImg} className="img-fluid" />
                    </div>
                  </div>
                  <div className="d-flex handle-container-main justify-content-evenly align-items-center">
                    <ActionButton
                      onTakeBack={onTakeBack}
                      isBackButtonEnabled={isBackButtonEnabled}
                      onResign={onResign}
                    />
                  </div>
                </Col>
              </div>
            </>
          )}
        </div>
        <Modal
          centered
          show={isUserLost || isUserWon}
          onHide={onFinishGame}
          dialogClassName="modal-container"
          contentClassName="result-modal-container"
        >
          {gameInfo.isGuest && (
            <GuestRegistration onClickOkay={onFinishGame} isUserWon={isUserWon} />
          )}
          {!gameInfo.isGuest && isUserLost && <LoseGameModal onClickOkay={onFinishGame} />}
          {!gameInfo.isGuest && isUserWon && (
            <WinGameModal onClickReward={onFinishGame} isWeb3={Boolean(walletAddress)} />
          )}
          <AdsComponent />
        </Modal>
        <Modal
          centered
          show={isExitVisible}
          onHide={onClickNo}
          dialogClassName="modal-container"
          contentClassName="result-modal-container"
        >
          {isExitVisible && <ExitModal onClickOkay={onFinishGame} onClickNo={onClickNo} />}
          <AdsComponent />
        </Modal>
      </div>
    </div>
  )
}

export default GameBoard

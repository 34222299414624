import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { ethers } from 'ethers'

import useUserStore from '../../store/UserStore'
import { connectWallet, toastErrorMixin } from '../../utils/interact'

import './Whitelist.scss'

const contractABI = require('../../abis/Whitelist.json')

const Whitelist = () => {
  const { walletAddress, setWalletAddress } = useUserStore()
  const [loading, setLoading] = useState(false)
  const [joinedWhitelist, setJoinedWhitelist] = useState(false)
  const [numberOfWhitelisted, setNumberOfWhitelisted] = useState(0)

  useEffect(async () => {
    getNumberOfWhitelisted()

    if (walletAddress) {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const chainId = await provider.send('eth_chainId')

      if (chainId !== process.env.REACT_APP_POLYGON_TESTNET_CHAIN_ID) {
        toastErrorMixin.fire({ title: 'In order to process, switch to the mumbai network!' })
        return
      }

      checkIfAddressInWhitelist()
    }
  }, [walletAddress])

  const getNumberOfWhitelisted = async () => {
    try {
      let provider = ethers.getDefaultProvider(process.env.REACT_APP_BLOCKCHAIN_NODE_URL)

      if (walletAddress) {
        provider = new ethers.providers.Web3Provider(window.ethereum)
      }

      const whitelistContract = new ethers.Contract(process.env.REACT_APP_WHITELIST_CONTRACT_ADDRESS, contractABI, provider)
      const _numberOfWhitelisted = await whitelistContract.numAddressesWhitelisted()
      setNumberOfWhitelisted(_numberOfWhitelisted)
    } catch (err) {
      console.error(err)
    }
  }

  const addAddressToWhitelist = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      setLoading(true)
      const whitelistContract = new ethers.Contract(process.env.REACT_APP_WHITELIST_CONTRACT_ADDRESS, contractABI, signer)
      const tx = await whitelistContract.addAddressToWhitelist()
      await tx.wait()
      setLoading(false)
      await getNumberOfWhitelisted()
      setJoinedWhitelist(true)
    } catch (e) {
      setLoading(false)
      toastErrorMixin.fire({ title: 'Error with adding you to whitelist!' })
    }
  }

  const checkIfAddressInWhitelist = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const whitelistContract = new ethers.Contract(process.env.REACT_APP_WHITELIST_CONTRACT_ADDRESS, contractABI, signer)
      const address = await signer.getAddress()
      const _joinedWhitelist = await whitelistContract.whitelistedAddresses(address)
      setJoinedWhitelist(_joinedWhitelist)
    } catch (err) {
      console.error(err)
    }
  }

  const connectToWhitelist = async () => {
    try {
      const response = await connectWallet()

      if (response.address) {
        setWalletAddress(response.address)
      } else {
        toastErrorMixin.fire({ title: 'Error with connecting your wallet!' })
      }
    } catch (e) {
      toastErrorMixin.fire({ title: 'Error with connecting your wallet!' })
    }
  }

  const renderButton = () => {
    if (walletAddress) {
      if (joinedWhitelist) {
        return (
          <div className='whitelist-page__text'>
            You have already in whitelist! Thanks!
          </div>
        )
      } else if (loading) {
        return <div className='whitelist-page__loading-text'>Loading...</div>
      } else {
        return (
          <button className='whitelist-page__join-button' onClick={addAddressToWhitelist}>
            Join the Whitelist
          </button>
        )
      }
    } else {
      return (
        <button className='whitelist-page__connect-button' onClick={connectToWhitelist}>
          Connect your wallet
        </button>
      )
    }
  }

  return (
    <Container className="whitelist-page-container container-fluid">
      <div className="whitelist-page__title">ChessX Whitelist</div>
      <div className='whitelist-page__text'>
        ChessX NFT collection for early adapters!
      </div>
      <div className='whitelist-page__text'>
        {numberOfWhitelisted} users have already joined the whitelist!
      </div>
      {renderButton()}
    </Container>
  )
}

export default Whitelist

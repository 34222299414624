import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import useGameStore from '../../../store/GameStore'
import useUserStore from '../../../store/UserStore'
import socketEvents from '../../../utils/packet'
import './Match.scss'
import { Button } from 'react-bootstrap'
import UserIcon from '../../../assets/img/user.png'

const gameObj = {}
let timeoutId

const Match = ({ matching, setMatching, rank, stopMatching }) => {
  const navigate = useNavigate()
  const { socket, setIsConnected, setGameInfo, isConnected } = useGameStore()
  const { walletAddress, user, userId, guestId } = useUserStore()

  useEffect(() => {
    if (typeof socket === 'undefined') navigate('/')
    else {
      socket.on(socketEvents.SC_RoomJoined, ({ gameInfo }) => {
        setMatching(false)
        setIsConnected(true)
        setGameInfo(gameInfo)
        navigate('/play')
      })

      socket.on(socketEvents.SC_PvAIRoomJoined, ({ gameInfo }) => {
        setMatching(false)
        setIsConnected(true)
        setGameInfo(gameInfo)
        navigate('/play')
      })
    }

    if (typeof socket !== 'undefined') {
      return () => {
        socket.off(socketEvents.SC_RoomCreated)
        socket.off(socketEvents.SC_RoomJoined)
        socket.off(socketEvents.SC_PvAIRoomJoined)
      }
    }
  }, [socket, user, walletAddress])

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (gameObj.hasOwnProperty('baseTime') && gameObj.hasOwnProperty('addTime') && matching) {
      timeoutId = setTimeout(() => {
        if (matching && !isConnected) {
          const timestamp = Math.floor(Date.now() / 1000)
          socket.emit(socketEvents.CS_JoinPvAIRoom, {
            base: gameObj.baseTime,
            add: gameObj.addTime,
            user_id: userId,
            walletAddress,
            socketId: socket.id,
            userRank: rank,
            timestamp
          })
        }
      }, 7000)
    }
    if ((!matching || isConnected) && timeoutId) {
      console.log('timeoutId : ', timeoutId)
      socket.emit(socketEvents.CS_CLOSEROOM, {
        base: gameObj.baseTime,
        add: gameObj.addTime,
        user_id: userId
      })
      clearTimeout(timeoutId)
    }
  }, [matching, isConnected])

  return (
    <div className="user-detail-container col-lg-4 col-10 col-md-6 mx-auto">
      <div className="anonymous-img my-5">
        <img src={UserIcon} alt="Cryptonpunk" className="user-img" />
        <div className="anonymous-text">
          <p className="anonymous-paragraph">{user?.userName || guestId}</p>
        </div>
      </div>
      <div className="position-absolute bottom-0 col-lg-4 col-md-6 col-sm-3 col-10">
        <div className="puzzle-button-parent button-parent mb-5">
          <Button
            className="puzzle-button button-data"
            onClick={matching ? stopMatching : undefined}
          >
            {matching ? 'CANCEL' : 'PUZZLE'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Match

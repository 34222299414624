import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Chessboard } from 'react-chessboard'
import './ChessBoard.scss'
import BoardDark from '../../../assets/img/board-dark.png'
import BoardLight from '../../../assets/img/board-light.png'
import useUserStore from '../../../store/UserStore'
import { getChessPiece } from '../../../libraries/common'
import { Chess } from 'chess.js'
import { getPiecePositions } from '../../../utils/pieces'

let selectedPiece = null
// eslint-disable-next-line react/display-name
const ChessBoard = forwardRef(({ position, game, orientation, turn, onParentDrop }, ref) => {
  const [width, setWidth] = useState(0)
  const [customPiecesPosition, setCustomPiecesPosition] = useState({})
  const [selected, setSelected] = useState(null)
  const [availableMoves, setAvailableMoves] = useState([])

  const { collection } = useUserStore()
  React.useEffect(() => {
    window.addEventListener('resize', changeWidth)
    return () => window.removeEventListener('resize', changeWidth)
  }, [])

  React.useEffect(() => {
    changeWidth()
  }, [])

  const changeWidth = () => {
    if (window.innerWidth > 1100) {
      setWidth(1500 / 3)
      return
    }
    if (window.innerWidth > 992) {
      setWidth(1500 / 4)
      return
    } else if (window.innerWidth > 768) {
      setWidth(992 / 3 - 60)
      return
    } else if (window.innerWidth > 600) {
      setWidth(600 / 1.3)
      return
    } else if (window.innerWidth > 424) {
      setWidth(360 / 1)
      return
    } else if (window.innerWidth > 374) {
      setWidth(300 / 1)
      return
    } else if (window.innerWidth > 319) {
      setWidth(250 / 1)
      return
    }
    setWidth(600 / 2.43)
  }

  const renderPiece = positionURL => {
    const renderObject = {}
    Object.keys(positionURL).forEach(ele => {
      renderObject[ele] = ({ squareWidth }) => (
        <div
          style={{
            width: squareWidth,
            height: squareWidth,
            backgroundImage: positionURL[ele],
            backgroundSize: '100%'
          }}
        />
      )
    })
    return renderObject
  }

  useEffect(async () => {
    if (!collection) {
      return
    }

    const pieces = {}
    collection?.forEach(item => {
      if (item.position) {
        pieces[item.marketplace.color + item.marketplace.class.toUpperCase()] = renderPiece({
          [item.position]: `url(${
            item.marketplace.icon // isNFT3dView ? item.marketplace.avatar : item.marketplace.icon
          })`
        })
      }
    })
    if (Object.keys(pieces)) {
      setCustomPiecesPosition(pieces)
    }
  }, [collection?.length]) //, isNFT3dView])

  useEffect(() => {
    const chess = new Chess(position)
    if (chess.inCheck()) {
      const square = getPiecePositions(chess, { type: 'k', color: chess.turn() })
      const source = document.querySelector(`div[data-square="${square}"]`)
      if (source) {
        source.classList.add('in-check-square')
        source.style.backgroundImage =
          'radial-gradient(rgb(193, 16, 16), transparent), ' + source.style.backgroundImage
      }
    } else {
      const source = document.querySelectorAll('.in-check-square')
      for (const i in source) {
        if (source[i] instanceof Node) {
          source[i].style.backgroundImage = source[i].style.backgroundImage.replace(
            'radial-gradient(rgb(193, 16, 16), transparent), ',
            ''
          )
          source[i].classList.remove('in-check-square')
        }
      }
    }
  }, [position])

  const isDraggablePiece = ({ piece, sourceSquare }) => {
    if (game.isGameOver()) return false
    // or if it's not that side's turn
    if (
      (orientation === 'white' && piece.search(/^b/) !== -1) ||
      (orientation === 'black' && piece.search(/^w/) !== -1)
    ) {
      return false
    }

    return true
  }

  const onPieceDrop = (sourceSquare, targetSquare, piece) => {
    if (game.isGameOver()) return false
    // or if it's not that side's turn
    if (
      (orientation === 'white' && piece.search(/^b/) !== -1) ||
      (orientation === 'black' && piece.search(/^w/) !== -1)
    ) {
      return false
    }

    onDrop({ sourceSquare, targetSquare })
    return true
  }

  const onDrop = ({ sourceSquare, targetSquare }) => {
    if (game.turn() !== turn) {
      return
    }
    resetSquares()
    removeDragGreySquares()

    const qrPieces = document.querySelectorAll('[data-testid^="bR"],[data-testid^="bQ"]')
    if (qrPieces.length > 0) {
      for (const i in qrPieces) {
        qrPieces.item(i).querySelector('svg g svg g').style.fill = 'rgb(0,0,0)'
      }
    }

    highlightLastMoves({
      sourceSquare,
      targetSquare
    })

    onParentDrop({ sourceSquare, targetSquare })
  }

  const onSquareClick = square => {
    if (!selectedPiece || selected === square) {
      resetSquares()
      return
    } else if (availableMoves.indexOf(square) !== -1) {
      onDrop({ sourceSquare: selected, targetSquare: square })
      return
    }

    if (
      (orientation === 'white' && selectedPiece.search(/^b/) !== -1) ||
      (orientation === 'black' && selectedPiece.search(/^w/) !== -1)
    ) {
      resetSquares()
      return
    }

    setSelected(square)
    removeGreySquares()
    const moves = game.moves({
      square,
      verbose: true
    })
    // highlight the square they moused over
    const piece = game.get(square)
    greySquare(square, `${piece?.color}${piece?.type?.toUpperCase()}`)
    // exit if there are no moves available for this square
    if (moves.length === 0) return
    // highlight the possible squares for this piece
    const allMoves = moves.map(obj => {
      if (obj.captured) greySquareCaptureDot(obj.to)
      else greySquareDot(obj.to)
      return obj.to
    })
    setAvailableMoves(allMoves)
  }

  const onPieceClick = piece => {
    selectedPiece = piece
  }

  const onPieceDragBegin = (piece, square) => {
    selectedPiece = piece
    onSquareClick(square)
  }

  const onDragOverSquare = square => {
    removeDragGreySquares()
    greyDragSquare(square)
  }

  const removeDragGreySquares = () => {
    const elems = document.querySelectorAll('.grey-square-drag')
    for (const i in elems) {
      if (elems[i] instanceof Node) elems[i].classList.remove('grey-square-drag')
    }
  }

  const greyDragSquare = square => {
    const elem = document.querySelector(`div[data-square="${square}"]`)
    if (
      elem.childNodes[0] &&
      (elem.childNodes[0].querySelector('.grey-square-dot') !== null ||
        elem.childNodes[0].querySelector('.grey-square-capture') !== null)
    ) {
      elem.classList.add('grey-square-drag')
    }
  }

  const removeGreySquares = () => {
    const elems = document.querySelectorAll('.grey-square')
    for (const i in elems) {
      if (elems[i] instanceof Node) elems[i].classList.remove('grey-square')
    }
    const elemsDot = document.querySelectorAll('.grey-square-dot')
    for (const i in elemsDot) {
      if (elemsDot[i] instanceof Node) elemsDot[i].classList.remove('grey-square-dot')
    }
    const elemsDotCap = document.querySelectorAll('.grey-square-capture')
    for (const i in elemsDotCap) {
      if (elemsDotCap[i] instanceof Node) elemsDotCap[i].classList.remove('grey-square-capture')
    }
    const elemsPieces = document.querySelectorAll('.pawn-pieces')
    for (const i in elemsPieces) {
      if (elemsPieces[i] instanceof Node) {
        elemsPieces[i].classList.remove('pawn-pieces')
      }
    }
    const pawn = document.getElementsByClassName('piece-img')
    while (pawn[0]) {
      pawn[0].parentNode.removeChild(pawn[0])
    }
  }

  const greySquare = (square, piece) => {
    const elem = document.querySelector(`div[data-square="${square}"]`)
    elem.classList.add('grey-square')
    const dot = document.createElement('div')
    dot.classList.add('pawn-pieces')
    elem.childNodes[0].appendChild(dot)
    const svg = document.getElementsByClassName('pawn-pieces')
    svg[0].outerHTML += getChessPiece(piece)
  }

  const removeLastHighlight = () => {
    const source = document.querySelectorAll('.grey-highlight-square')
    for (const i in source) {
      if (source[i] instanceof Node) source[i].classList.remove('grey-highlight-square')
    }
    const target = document.querySelectorAll('.grey-highlight-square-target')
    for (const i in target) {
      if (target[i] instanceof Node) target[i].classList.remove('grey-highlight-square-target')
    }
  }

  const highlightLastMoves = ({ sourceSquare, targetSquare }) => {
    removeLastHighlight()
    const source = document.querySelector(`div[data-square="${sourceSquare}"]`)
    source.classList.add('grey-highlight-square')
    const target = document.querySelector(`div[data-square="${targetSquare}"]`)
    target.classList.add('grey-highlight-square-target')
  }

  const greySquareDot = square => {
    const elem = document.querySelector(`div[data-square="${square}"]`)

    const dot = document.createElement('div')
    dot.classList.add('grey-square-dot')
    elem.childNodes[0].appendChild(dot)
  }

  const greySquareCaptureDot = square => {
    const elem = document.querySelector(`div[data-square="${square}"]`)

    const dot = document.createElement('div')
    dot.classList.add('grey-square-capture')
    elem.childNodes[0].appendChild(dot)
  }

  const resetSquares = () => {
    setSelected(null)
    setAvailableMoves([])
    removeGreySquares()
  }

  useImperativeHandle(ref, () => ({
    highlightLastMoves,
    removeLastHighlight,
    resetSquares
  }))

  return (
    <div className="chessboard-container">
      <Chessboard
        backgroundImage
        position={position}
        arePremovesAllowed={true}
        clearPremovesOnRightClick={true}
        arePiecesDraggable={true}
        boardWidth={width}
        isDraggablePiece={isDraggablePiece}
        boardOrientation={orientation}
        onPieceDrop={onPieceDrop}
        onPieceDragBegin={onPieceDragBegin}
        onDragOverSquare={onDragOverSquare}
        onSquareClick={onSquareClick}
        onPieceClick={onPieceClick}
        onMouseOverSquare={onDragOverSquare}
        customDropSquareStyle={{ opacity: '1' }}
        customPiecesPosition={customPiecesPosition}
        showBoardNotation={false}
        customLightSquareStyle={{
          backgroundImage: `url(${BoardLight})`,
          boxShadow: 'none'
        }}
        customDarkSquareStyle={{
          backgroundImage: `url(${BoardDark})`,
          boxShadow: 'none'
        }}
        customPremoveLightSquareStyle={{
          backgroundColor: '#827b85',
          boxShadow: 'none'
        }}
        customPremoveDarkSquareStyle={{
          backgroundColor: '#64535c',
          boxShadow: 'none'
        }}
      />
    </div>
  )
})

export default ChessBoard

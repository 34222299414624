import React from 'react'
import { Button } from 'react-bootstrap'
import './ActionButton.scss'

const ActionButton = ({ onTakeBack, isBackButtonEnabled, onResign }) => {
  return (
    <>
      <div className="button-parent_orange">
        <Button className="button_orange" onClick={onTakeBack} disabled={!isBackButtonEnabled}>
          TAKE BACK
        </Button>
      </div>
      <div className="button-parent_orange">
        <Button className="button_orange" disabled={true}>
          PREVIEW
        </Button>
      </div>
      <div className="button-parent_red">
        <Button className="button_red" onClick={onResign}>
          RESIGN
        </Button>
      </div>
    </>
  )
}

export default ActionButton

import React, { useEffect, useState } from 'react'
import { Container, Nav, Tab } from 'react-bootstrap'
import { useQueryClient } from 'react-query'

import './ChessApiAnalytics.scss'
import { fetchChessApi } from './ChessApi'
import CDropdown from '../../components/Common/CDropdown/CDropdown'
import { MONTHS } from '../../constants'
import { Table as TableIcon, BarChartFill } from 'react-bootstrap-icons'
import ChessApiTable from './ChessApiTable'
import ChessApiChart from './ChessApiChart'

export const calculateAvgLast10Time = last10Time => {
  const total = last10Time?.reduce((sum, time) => sum + time, 0)
  return (total / last10Time?.length).toFixed(2)
}

export const formatApiName = name => {
  if (name.startsWith('chessx-')) {
    return name.replace('chessx-', '')
  }
  return name
}

const ChessApiAnalytics = () => {
  const queryClient = useQueryClient()
  const [activeTab, setActiveTab] = useState('table')
  const [duration, setDuration] = useState(null)
  const [filteredData, setFilteredData] = useState(null)
  const [dropDownData, setDropDownData] = useState({ months: [], years: [] })

  useEffect(() => {
    return () => {
      queryClient.cancelQueries(['CHESS_API'])
    }
  }, [])

  const { isLoading, data } = fetchChessApi()

  React.useEffect(() => {
    if (data) {
      const date = new Date()
      const month = (date.getMonth() + 1).toString()
      const year = date.getFullYear().toString()
      const months = MONTHS.filter(month => {
        return data.find(item => item.month === month.value && item.year === year)
      }).sort((a, b) => b.value - a.value)

      const years =
        data &&
        [...new Set(data.map(item => item.year))]
          ?.sort((a, b) => b - a)
          ?.map(year => ({ name: year, value: year }))

      setDropDownData({ months, years })
      setDuration({
        month: MONTHS.find(item => item.value === month),
        year: { name: year, value: year }
      })
    }
  }, data)

  React.useEffect(() => {
    if (duration && data.length) {
      const filterdData = data.find(
        item => item.month === duration.month.value && item.year === duration.year.value
      )
      setFilteredData(filterdData ? filterdData.meta : null)
    }
  }, [duration])

  if (isLoading) {
    return <div>Loading...</div>
  }

  const handleTabChange = tabKey => {
    setActiveTab(tabKey)
  }

  const handleMonthChange = month => {
    setDuration({ ...duration, month })
  }
  const handleYearChange = year => {
    const months = MONTHS.filter(month => {
      return data.find(item => item.month === month.value && item.year === year.value)
    }).sort((a, b) => b.value - a.value)

    setDropDownData({ months, years: dropDownData.years })
    const findMonth = months.findIndex(item => item.value === duration.month.value)
    setDuration({ month: findMonth !== -1 ? duration.month : months[0], year })
  }
  return (
    <Container className="api-container">
      <div className="header-container">
        <div className="title">CHESS APIs</div>
        <div className="duration-container">
          <div className="month-container">
            <CDropdown
              data={dropDownData.months}
              onSelect={handleMonthChange}
              selectedItem={duration?.month}
            />
          </div>
          <CDropdown
            data={dropDownData.years}
            onSelect={handleYearChange}
            selectedItem={duration?.year}
          />
        </div>
      </div>
      {filteredData
        ? (
        <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
          <Nav variant="pills" className="justify-content-end tab-bar">
            <Nav.Item className="mt-2">
              <Nav.Link eventKey="table" className={activeTab === 'table' ? 'active' : ''}>
                <TableIcon /> Table
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mt-2">
              <Nav.Link eventKey="chart" className={activeTab === 'chart' ? 'active' : ''}>
                <BarChartFill /> Chart
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="table">
              <ChessApiTable data={filteredData} />
            </Tab.Pane>
            <Tab.Pane eventKey="chart">
              <ChessApiChart data={filteredData} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
          )
        : (
        <div>No data found</div>
          )}
    </Container>
  )
}
export default ChessApiAnalytics

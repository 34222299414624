import React from 'react'
import { Outlet } from 'react-router-dom'
import HomeHeader from '../HomeHeader'
import './UserLayout.scss'

const UserLayout = ({ children }) => {
  return (
    <div className="header-detail-container">
      <HomeHeader />
      {children}
      <Outlet />
    </div>
  )
}

export default UserLayout

import create from 'zustand'
import { DEFAULT_FILTER } from '../constants'

const useGameStore = create((set) => ({
  socket: undefined,
  isConnected: false,
  isPlaying: false,
  gameInfo: {},
  collectionFilters: DEFAULT_FILTER,
  marketPlaceFilters: DEFAULT_FILTER,
  adminMarketPlaceFilters: DEFAULT_FILTER,
  setWalletAddress: (value) => { set({ walletAddress: value }) },
  setSocket: (value) => { set({ socket: value }) },
  setIsConnected: (value) => { set({ isConnected: value }) },
  setIsPlaying: (value) => { set({ isPlaying: value }) },
  setGameInfo: (value) => { set({ gameInfo: value }) },
  setCollectionFilters: (value) => { set({ collectionFilters: value }) },
  setMarketPlaceFilters: (value) => { set({ marketPlaceFilters: value }) },
  setAdminMarketPlaceFilters: (value) => { set({ adminMarketPlaceFilters: value }) }
}))

export default useGameStore

import React from 'react'
import { ethers } from 'ethers'
import Swal from 'sweetalert2'

export const toastMixin = Swal.mixin({
  toast: true,
  icon: 'success',
  title: 'General Title',
  position: 'top-right',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export const toastErrorMixin = Swal.mixin({
  toast: true,
  icon: 'error',
  title: 'Error',
  position: 'top-right',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export const connectWallet = async () => {
  const POLYGON_TESTNET_CHAIN_ID = process.env.REACT_APP_POLYGON_TESTNET_CHAIN_ID

  if (window.ethereum) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)

      let chainId = await provider.send('eth_chainId')
      if (chainId !== POLYGON_TESTNET_CHAIN_ID) {
        await provider.send('wallet_switchEthereumChain', [{ chainId: POLYGON_TESTNET_CHAIN_ID }])
        chainId = await provider.send('eth_chainId')
      }

      if (chainId === POLYGON_TESTNET_CHAIN_ID) {
        const addressArray = await provider.send('eth_requestAccounts', [])

        if (addressArray.length > 0) {
          return { address: addressArray[0], status: '👆🏽 Your wallet is connected to the site.' }
        } else {
          toastMixin.fire({ title: '😥 Connect your wallet account to the site.', icon: 'error' })
          return { address: '', status: '😥 Connect your wallet account to the site.' }
        }
      }
    } catch (err) {
      return {
        address: '',
        status: '😥 ' + err.message
      }
    }
  } else {
    toastMixin.fire({
      title:
        '🦊 You must install Metamask, a virtual Ethereum wallet, in your browser.(https://metamask.io/download.html)',
      icon: 'error'
    })
    return {
      address: '',
      status: (
        <span>
          <p>
            {' '}
            🦊{' '}
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.(https://metamask.io/download.html)
          </p>
        </span>
      )
    }
  }
}

export const getProvider = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  return { provider, signer }
}

export const getPWNTokenContract = () => {
  const { signer } = getProvider()
  const contractABI = require('../abis/PwnToken.json')

  const contract = new ethers.Contract(
    process.env.REACT_APP_TOKEN_ADDRESS,
    contractABI,
    signer
  )

  return contract
}

export const getWhitelistContract = () => {
  const { signer } = getProvider()
  const contractABI = require('../abis/Whitelist.json')

  const contract = new ethers.Contract(
    process.env.REACT_APP_WHITELIST_CONTRACT_ADDRESS,
    contractABI,
    signer
  )

  return contract
}

export const getPWNBalance = async (walletAddress) => {
  if (!walletAddress) {
    return 'X'
  }

  const contract = getPWNTokenContract()
  const balance = await contract.balanceOf(walletAddress)

  return ethers.utils.formatEther(balance)
}

export const getUniqueId = () => {
  const uniqueId = Math.random().toString(36).substring(2, 9)
  return uniqueId
}

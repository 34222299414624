import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'

import './LogoutComponent.scss'

const LogoutComponent = ({ onLogout, onCancel, lightTheme }) => {
  return (
    <Row className="logout-component">
      <div
        className={`logout-component__container ${
          lightTheme ? 'logout-light__container' : 'logout-dark__container'
        }`}
      >
        <h2 className={`mb-4 text-center ${lightTheme ? 'dark-text' : 'white-text'}`}>
          Log out off ChessX?
        </h2>
        <Row className="mb-3">
          <Col>
            <Button onClick={onLogout} className="logout-btn">
              Yes
            </Button>
          </Col>
          <Col>
            <Button onClick={onCancel} className="cancel-btn">
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    </Row>
  )
}

LogoutComponent.defaultProps = {
  lightTheme: false
}

export default LogoutComponent

import React from 'react'
import { Container } from 'react-bootstrap'

const Settings = props => {
  return (
    <Container className="p-0">
      <div className="title">Admin Settings</div>
    </Container>
  )
}
export default Settings

import React, { useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import './LoginComponent.scss'
import { connectWallet, toastErrorMixin } from '../../../utils/interact'
import { Constants } from '../../../utils/packet'
import { validateEmail } from '../../../libraries/common'
import useUserStore from '../../../store/UserStore'
import axiosInstance from '../../../network/axiosInstance'
import { EmailIcon, EyeIcon, EyeClosedIcon, LockIcon } from '../../../assets/icons'

import metamaskLogo from '../../../assets/img/metamask.png'

const LoginComponent = ({ onSuccess, toggleLogin, toggleRegister, toggleForgotPassword }) => {
  const { walletAddress } = useUserStore()
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
    // Check and see if errors exist, and remove them from the error object:
    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      })
    }
  }

  const findFormErrors = () => {
    const { email, password } = form
    const newErrors = {}
    if (!email || email === '') newErrors.email = 'Email cannot be blank!'
    else if (!validateEmail(email)) newErrors.email = 'Email should be valid!'
    if (!password || password === '') newErrors.password = 'Password cannot be blank!'
    else if (password.length < 7) {
      newErrors.password = 'Password should be at least 8 character long!'
    }

    return newErrors
  }

  const loginWithMetamask = async () => {
    try {
      const response = await connectWallet()

      if (response.address) {
        const params = { walletId: response.address }
        const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/login'
        const res = await axiosInstance.post(url, params)

        if (res && res.data && res.data.status === 200) {
          localStorage.setItem(Constants.USER, JSON.stringify(res.data.data))
          onSuccess({ ...res.data.data, walletConnected: true })
        } else {
          toastErrorMixin.fire({ title: res.data.message })
        }
      }
    } catch (e) {
      toastErrorMixin.fire({ title: 'Register error!' })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const newErrors = findFormErrors()
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setSubmitting(true)
      try {
        const params = walletAddress
          ? {
              ...form,
              walletId: walletAddress
            }
          : form
        const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/login'
        const res = await axiosInstance.post(url, params)
        if (res && res.data && res.data.status === 200) {
          localStorage.setItem(Constants.USER, JSON.stringify(res.data.data))
          onSuccess(res.data.data)
        } else {
          toastErrorMixin.fire({
            title: res.data.message
          })
        }
      } catch (err) {
        console.log('error', err)
        toastErrorMixin.fire({
          title: 'Error logging in.'
        })
      } finally {
        setSubmitting(false)
      }
    }
  }

  const handleForgotPassword = () => {
    toggleLogin()
    toggleForgotPassword()
  }

  const [passwordShown, setPasswordShown] = useState(false)
  const togglePass = () => {
    setPasswordShown(state => !state)
  }

  return (
    <Row className="quick-match-area">
      <div className="login-container">
        <h1 className="title">LOGIN</h1>
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom02" className="form-container">
              <InputGroup hasValidation={true} className={`${errors.email ? 'is-invalid' : ''}`}>
                <InputGroup.Text>
                  <EmailIcon height={20} width={20} fill="#737171" />
                </InputGroup.Text>
                <Form.Control
                  className="inputboxEmail"
                  required
                  type="email"
                  placeholder="Email"
                  onChange={e => setField('email', e.target.value)}
                  isInvalid={!!errors.email}
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom05">
              <InputGroup hasValidation={true} className={`${errors.password ? 'is-invalid' : ''}`}>
                <InputGroup.Text>
                  <LockIcon height={20} width={20} />
                </InputGroup.Text>
                <Form.Control
                  className="form-password"
                  type={passwordShown ? 'text' : 'password'}
                  placeholder="Password"
                  required
                  minLength={8}
                  onChange={e => setField('password', e.target.value)}
                  isInvalid={!!errors.password}
                />
                <InputGroup.Text>
                  <i onClick={togglePass}>
                    {passwordShown
                      ? <EyeClosedIcon height={20} width={20} />
                      : <EyeIcon height={20} width={20} />
                    }
                  </i>
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="mb-3 d-flex justify-content-end">
            <div className="forgot-password" onClick={handleForgotPassword}>
              Forgot Password?
            </div>
          </div>
          <Row className="mb-3">
            <Col>
              <div className="button-parent">
                <Button disabled={submitting} type="submit" className="button-container">
                  {submitting ? <Spinner size="sm" animation="border" /> : 'LOGIN'}
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <div className="button-parent">
                <Button
                  disabled={submitting}
                  className="button-container"
                  onClick={toggleLogin}
                >
                  CANCEL
                </Button>
              </div>
            </Col>
            </Row>
          <Row className="mb-3">
            <Col>
            <div className="button-parent">
              <Button disabled={submitting} onClick={loginWithMetamask} className="button-container">
                <img className="metamask-logo" src={metamaskLogo} />
                {submitting ? <Spinner size="sm" animation="border" /> : ' Log In with Metamask'}
              </Button>
            </div>
            </Col>
          </Row>
        </Form>
        <Row className="mb-3 mt-4">
          <Col className="already-account">
            {
              // eslint-disable-next-line react/no-unescaped-entities
              "Don't have an account?"
            }
            <div
              className="register-btn"
              onClick={() => {
                toggleLogin()
                toggleRegister()
              }}
            >
              Register
            </div>
          </Col>
        </Row>
      </div>
    </Row>
  )
}

export default LoginComponent

import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Pagination, Row, Table } from 'react-bootstrap'
import { useQueryClient } from 'react-query'
import { DEFAULT_FILTER, MARKETPLACE_PAGE_LIMIT } from '../../constants'
import useAdminStore from '../../store/AdminStore'
import { fetchMarketplace } from '../Marketplace/MarketplaceApi'
import './AdminMarketplace.scss'

const AdminMarketplace = props => {
  const [page, setPage] = useState(0)
  const [pagesArray, setPagesArray] = useState([])
  const queryClient = useQueryClient()
  const { updateMarketplace } = useAdminStore()
  const [nfts, setNfts] = useState([])

  const tableHead = [
    { id: 'avatar', label: 'Avatar' },
    { id: '_id', label: 'id' },
    { id: 'name', label: 'Name' },
    { id: 'owner', label: 'Owner' },
    { id: 'sale', label: 'For Sale' },
    { id: 'price', label: 'Price' },
    { id: 'bCount', label: 'Breed Count' },
    { id: 'color', label: 'Color' },
    { id: 'class', label: 'Class' },
    { id: 'powerName', label: 'Power Name' }
  ]

  useEffect(() => {
    return () => {
      queryClient.cancelQueries(['MARKETPLACE'])
    }
  }, [])

  const { isLoading, data } = fetchMarketplace(page, MARKETPLACE_PAGE_LIMIT, DEFAULT_FILTER)

  useEffect(() => {
    if (Array.isArray(data?.data)) {
      const pagesArray = [...Array(Math.ceil(data.totalCount / MARKETPLACE_PAGE_LIMIT) || 1).keys()]
      setPagesArray(pagesArray)
      setNfts(data.data)
    }
  }, [data?.data?.length])

  const handleSaleClick = async (e, data) => {
    try {
      const updatedData = nfts.map(obj => {
        if (obj._id === data._id) return { ...data, sale: e.target.checked ? 'forsale' : 'notforsale' }
        return obj
      })
      setNfts(updatedData)
      const params = {
        _id: data._id,
        sale: e.target.checked ? 'forsale' : 'notforsale'
      }
      await updateMarketplace(data._id, params)
    } catch (error) {
      const undoUpdatedData = nfts.map(obj => {
        if (obj._id === data._id) return data
        return obj
      })
      setNfts(undoUpdatedData)
      console.log(error)
    }
  }

  return (
    <Container className="marketplace-container">
      <div className="title">Marketplace</div>
      <div className="main-detail-container">
        <Row className="mt-4" style={{ height: '100%' }}>
          {!nfts && isLoading && <div>Loading...</div>}
          {nfts?.length
            ? (
                <Col xs={12}>
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th className='table-cell'>#</th>
                        {tableHead.map((ele, index) => (
                          <th key={index} className='table-cell'>{ele.label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {nfts.map((marketplaceData, index) => {
                        return (
                          <tr key={index}>
                            <td className='table-cell'>{page * MARKETPLACE_PAGE_LIMIT + (index + 1)}</td>
                            {tableHead.map((ele, ind) => {
                              if (ele.id === 'avatar') {
                                return (
                                  <td key={ind} className='avatar-container'>
                                    <div className="image-container">
                                      <img className="image" src={marketplaceData[ele.id]}></img>
                                    </div>
                                  </td>
                                )
                              }
                              if (ele.id === 'sale') {
                                return (
                                  <td key={ind} className='table-cell'>
                                    <Form.Check
                                      inline
                                      name="status"
                                      type="checkbox"
                                      className='m-auto'
                                      onChange={e => handleSaleClick(e, marketplaceData)}
                                      checked={marketplaceData[ele.id] === 'forsale'}
                                    />
                                  </td>
                                )
                              }
                              if (ele.id === 'price') {
                                return (
                                  <td key={ind} className='table-cell'>
                                    {marketplaceData[ele.id]} ETH
                                  </td>
                                )
                              }
                              return <td key={ind} className='table-cell'>{marketplaceData[ele.id]}</td>
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Col>
              )
            : null}
          <Pagination>
            {pagesArray.map((obj, index) => (
              <Pagination.Item key={index} onClick={() => setPage(obj)} active={obj === page}>
                {obj + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Row>
      </div>
    </Container>
  )
}
export default AdminMarketplace

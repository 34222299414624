import React, { memo, useRef, useLayoutEffect } from 'react'
import { Form } from 'react-bootstrap'
import usePrevious from '../../../hooks/usePrevious'

const SingleOTPInputComponent = props => {
  const { focus, autoFocus, ...rest } = props
  const inputRef = useRef(null)
  const prevFocus = usePrevious(!!focus)
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus()
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus()
        inputRef.current.select()
      }
    }
  }, [autoFocus, focus, prevFocus])

  return <Form.Control ref={inputRef} {...rest} />
}

const SingleOTPInput = memo(SingleOTPInputComponent)
export default SingleOTPInput

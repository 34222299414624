import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import './Collection.scss'

import PawnIcon from '../../assets/collection/pawn.png'
import KnightIcon from '../../assets/collection/knight.png'
import RookIcon from '../../assets/collection/rook.png'
import BishopIcon from '../../assets/collection/bishop.png'
import QueenIcon from '../../assets/collection/queen.png'
import KingIcon from '../../assets/collection/king.png'

import useUserStore from '../../store/UserStore'
import PieceDetail from '../../components/Common/Modals/PieceDetails'

const Collection = () => {
  const [pieceDetailVisible, setPieceDetailVisible] = useState(false)
  const [selectedPiece, setSelectedPiece] = useState(null)
  const { collection, inUseCollection, fetchUserCollection, totalCollectionCount } = useUserStore()
  const [color, setColor] = useState('w')

  const toggleColor = () => {
    setColor(s => (s === 'w' ? 'b' : 'w'))
  }

  useEffect(() => {
    fetchUserCollection(color)
  }, [color])

  const togglePieceDetail = item => {
    if (item && item._id) setSelectedPiece(item)
    else setSelectedPiece(null)

    setPieceDetailVisible(state => !state)
  }

  const renderSelectedItem = item => {
    return (
      <div onClick={() => togglePieceDetail(item)} className="card-container-out">
        <div className="card-container">
          <div className="nft-image-container">
            <img src={item?.marketplace?.avatar} className="nft-image" />
          </div>
          <div className="piece-icon-container">
            <img src={item?.marketplace?.icon} className="piece-icon" />
          </div>
        </div>
      </div>
    )
  }

  const renderPieceItem = piece => {
    return (
      <div className="card-container-out">
        <div className="card-container">
          <img src={piece} className="piece-image" />
        </div>
      </div>
    )
  }

  return (
    <Container fluid className="collection-page">
      <Row className='px-2 px-lg-4'>
        <Col className="filter-area" xs={12} lg={3}>
          <Row className="deck-title mb-1 mb-lg-2 mb-lg-4">
            {color === 'w' ? 'White' : 'Black'} Deck
          </Row>
          <Row>
            <Col className="my-2 px-1 px-lg-3 d-flex justify-content-center" xs={2} lg={6}>
              {inUseCollection && inUseCollection[color + 'p']
                ? renderSelectedItem(inUseCollection[color + 'p'])
                : renderPieceItem(PawnIcon)}
            </Col>
            <Col className="my-2 px-1 px-lg-3 d-flex justify-content-center" xs={2} lg={6}>
              {inUseCollection && inUseCollection[color + 'n']
                ? renderSelectedItem(inUseCollection[color + 'n'])
                : renderPieceItem(KnightIcon)}
            </Col>
            <Col className="my-2 px-1 px-lg-3 d-flex justify-content-center" xs={2} lg={6}>
              {inUseCollection && inUseCollection[color + 'b']
                ? renderSelectedItem(inUseCollection[color + 'b'])
                : renderPieceItem(BishopIcon)}
            </Col>
            <Col className="my-2 px-1 px-lg-3 d-flex justify-content-center" xs={2} lg={6}>
              {inUseCollection && inUseCollection[color + 'r']
                ? renderSelectedItem(inUseCollection[color + 'r'])
                : renderPieceItem(RookIcon)}
            </Col>
            <Col className="my-2 px-1 px-lg-3 d-flex justify-content-center" xs={2} lg={6}>
              {inUseCollection && inUseCollection[color + 'q']
                ? renderSelectedItem(inUseCollection[color + 'q'])
                : renderPieceItem(QueenIcon)}
            </Col>
            <Col className="my-2 px-1 px-lg-3 d-flex justify-content-center" xs={2} lg={6}>
              {inUseCollection && inUseCollection[color + 'k']
                ? renderSelectedItem(inUseCollection[color + 'k'])
                : renderPieceItem(KingIcon)}
            </Col>
          </Row>
        </Col>
        <Col className="right-container mb-3" xs={12} lg={9}>
          <div className="filter-container">
            <div className="filter-details">
              <button
                className="filter-orange-button filter-button col-3 col-lg-2"
                onClick={toggleColor}
              >
                {color === 'w' ? 'Black' : 'White'}
              </button>
              <div className="detail-container col-6 col-lg-8">
                <div className="title-text">Piece Collection</div>
                <div className="filter-count-text">
                  Found: {collection?.length}/{totalCollectionCount}
                </div>
              </div>
              <button className="filter-blue-button filter-button col-3 col-lg-2">By Level</button>
            </div>
          </div>
          <div className="nft-container">
            <Row className="w-100 m-auto">
              {collection &&
                collection.length &&
                collection?.map((card, index) => (
                  <Col key={index} xs={3} lg={2} className="p-1 p-lg-2">
                    <div onClick={() => togglePieceDetail(card)} className="nft-card-container">
                      <div className="nft-image-container">
                        <img src={card?.marketplace?.avatar} className="nft-image" />
                      </div>
                      <div className="piece-icon-container">
                        <img src={card?.marketplace?.icon} className="piece-icon" />
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </Col>
      </Row>
      <Modal centered show={pieceDetailVisible} onHide={togglePieceDetail}>
        <PieceDetail
          togglePieceDetail={togglePieceDetail}
          pieceDetail={selectedPiece}
          inUseCollection={inUseCollection}
          color={color}
        />
      </Modal>
    </Container>
  )
}

export default Collection

import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { calculateAvgLast10Time, formatApiName } from './ChessApiAnalytics'

const tableHead = [
  { id: 'name', label: 'API NAME' },
  { id: 'last10Time', label: 'Avg execution time (last 10 calls)' },
  { id: 'maxTime', label: 'Max execution time (reset every month)' },
  { id: 'totalTime', label: 'Total number of calls (reset every month)' }
]

const renderRows = (data) => {
  return (
    data &&
      Object.keys(data).map((key, idx) => (
        <tr key={key}>
          <td className="table-cell">{idx}</td>
          <td className="table-cell">{formatApiName(key)}</td>
          <td className="table-cell">
            {calculateAvgLast10Time(data[key]?.last10Time)}
          </td>
          <td className="table-cell">{data[key].maxTime}</td>
          <td className="table-cell">{data[key].count}</td>
        </tr>
      ))
  )
}

const ChessApiTable = ({ data }) => {
  return (
     <div className="main-detail-container">
       <Row>
         {data && Object.keys(data)
           ? (
           <Col xs={12}>
             <Table responsive striped bordered hover>
               <thead>
                 <tr>
                   <th className="table-cell">#</th>
                   {tableHead.map((ele, index) => (
                     <th key={index} className="table-cell">
                       {ele.label}
                     </th>
                   ))}
                 </tr>
               </thead>
               <tbody>{renderRows(data)}</tbody>
             </Table>
           </Col>
             )
           : null}
       </Row>
     </div>
  )
}

export default ChessApiTable

import WhiteDefault from './Default_w.png'
import WhiteGrass from './Grass_w.png'
import WhiteOrange from './Orange_w.png'
import WhiteRed from './Red_w.png'
import WhiteSand from './Sand_w.png'
import WhiteStone from './Stone_w.png'
import BlackDefault from './Default_b.png'
import BlackGrass from './Grass_b.png'
import BlackOrange from './Orange_b.png'
import BlackRed from './Red_b.png'
import BlackSand from './Sand_b.png'
import BlackStone from './Stone_b.png'

export const White = { default: WhiteDefault, grass: WhiteGrass, orange: WhiteOrange, red: WhiteRed, sand: WhiteSand, stone: WhiteStone }
export const Black = { default: BlackDefault, grass: BlackGrass, orange: BlackOrange, red: BlackRed, sand: BlackSand, stone: BlackStone }
export const All = [WhiteDefault, WhiteGrass, WhiteOrange, WhiteRed, WhiteSand, WhiteStone, BlackDefault, BlackGrass, BlackOrange, BlackRed, BlackSand, BlackStone]

import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import './OpeningDb.scss'
import OpeningDBTable from './components/OpeningDBTable'
import useAdminStore from '../../store/AdminStore'
import SearchInput from './components/SearchInput'
import axiosInstance from '../../network/axiosInstance'
import { toastErrorMixin, toastMixin } from '../../utils/interact'

const OpeningDb = () => {
  const [openingData, setOpeningData] = useState(null)
  const [filterData, setFilterData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { fetchOpeningData } = useAdminStore()
  const [syncing, setSyncing] = useState(false)

  const convertToArray = data => {
    return Object.values(data).map(obj => {
      obj.id = obj._id
      if (obj?.children) obj.children = convertToArray(obj.children)
      return obj
    })
  }

  const getOpeningData = async () => {
    try {
      setIsLoading(true)
      const response = await fetchOpeningData()
      const arrayData = convertToArray(response.data)
      setOpeningData(arrayData)
      setFilterData(arrayData)
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getOpeningData()
  }, [])

  const fetchFilterData = (search, data) => {
    let values = []
    if (!data?.length) return values
    for (let i = 0; i <= data.length; i++) {
      if (
        data[i]?.name?.toLowerCase().includes(search) ||
        data[i]?.variation?.toLowerCase().includes(search) ||
        data[i]?.fen?.toLowerCase().includes(search)
      ) {
        values = values.concat(data[i])
        continue
      } else if (data[i]?.children && data[i]?.children?.length) {
        values = values.concat(fetchFilterData(search, data[i].children))
      }
    }
    return values
  }

  const handleChange = e => {
    if (e.target.value.trim()) {
      const data = fetchFilterData(e.target.value.toLowerCase(), openingData)
      setFilterData(data)
    } else {
      setFilterData(openingData)
    }
  }

  const syncOpeningDB = async user => {
    try {
      setSyncing(true)
      const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/opening/admin/sync-opening'
      const res = await axiosInstance.get(url)
      if (res.status === 200) {
        toastMixin.fire({
          title: 'Sync Successful'
        })
      }
    } catch (err) {
      toastErrorMixin.fire({
        title: 'Error syncing opening DB.'
      })
    } finally {
      setSyncing(false)
    }
  }

  return (
    <Container className="opening-db-container">
      <div className="title">
        Opening DB
        <div className="d-flex">
          <Button disabled={syncing} className="edit-btn" onClick={syncOpeningDB}>
            {syncing ? 'Syncing' : 'Sync Opening DB'}
          </Button>
        </div>
      </div>
      <SearchInput handleChange={handleChange} openingData={openingData} />
      <OpeningDBTable openingData={filterData} isLoading={isLoading} />
    </Container>
  )
}
export default OpeningDb

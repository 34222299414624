import create from 'zustand'
import { persist } from 'zustand/middleware'
import axiosInstance from '../network/axiosInstance'

import { toastMixin, getPWNBalance } from '../utils/interact'

const useUserStore = create(
  persist(
    (set, get) => ({
      user: null,
      pwns: 'X',
      userId: null,
      collection: null,
      walletAddress: null,
      guestId: null,
      token: null,
      refreshToken: null,
      rank: null,
      level: 1,
      completed: 0,
      inUseCollection: null,
      totalCollectionCount: 0,

      setUser: value => {
        set({ user: value })
      },
      setUserId: value => {
        set({ userId: value })
      },
      setPwnBalance: value => {
        set({ pwns: value })
      },
      setUserCollection: value => {
        set({ collection: value })
      },
      setInUseCollection: value => {
        set({ inUseCollection: value })
      },
      setWalletAddress: value => {
        set({ walletAddress: value })
      },
      setGuestId: value => {
        set({ guestId: value })
      },
      setToken: value => {
        set({ token: value })
      },
      setRefreshToken: value => {
        set({ refreshToken: value })
      },

      fetchHistory: async (page, count) => {
        try {
          const historyUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/games-history'
          const params = { userId: get().userId, page, count }
          const res = await axiosInstance.post(historyUrl, params)

          if (res.status === 200) {
            return res?.data
          }

          return null
        } catch (e) {
          toastMixin.fire({ title: `ERROR fetching games history: ${e.message}`, icon: 'error' })
        }
      },

      fetchUser: async id => {
        try {
          if (!id) {
            return get().user
          }

          const userUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/getUserDetail'
          const res = await axiosInstance.get(userUrl)

          if (res?.status === 200) {
            set({ user: res.data, userId: res.data._id })
          }
        } catch (e) {
          toastMixin.fire({ title: `ERROR fetching user: ${e.message}`, icon: 'error' })
        }
      },

      fetchRank: async () => {
        try {
          if (!get().token) return
          const rankUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/ranking/userRank'
          const res = await axiosInstance.get(rankUrl)
          if (res?.status === 200) {
            set({
              rank: res?.data?.rank || 1200,
              level: res?.data?.level,
              completed: res?.data?.completed
            })
          }
        } catch (e) {
          console.log('error', e)
          toastMixin.fire({ title: `ERROR fetching user rank: ${e.message}`, icon: 'error' })
        }
      },

      updateXP: async () => {
        try {
          if (!get().token) return
          const userXPUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/ranking/incrementXP'
          const res = await axiosInstance.get(userXPUrl)
          if (res?.status === 200) {
            set({
              rank: res?.data?.score || 1200,
              level: res?.data?.level,
              completed: res?.data?.completed
            })
          }
        } catch (e) {
          console.log('error', e)
          toastMixin.fire({
            icon: 'error',
            title: `ERROR updating XP: ${e.message}`
          })
        }
      },
      updateUser: async params => {
        const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/updateUser'
        const result = await axiosInstance.put(url, params)
        if (result?.status === 200) {
          set({ user: result?.data })
        }
      },

      fetchPWNBalance: async address => {
        try {
          const pwns = await getPWNBalance(address)
          set({ pwns })
        } catch (e) {
          toastMixin.fire({ title: `Error fetching PWN balance: ${e.message}`, icon: 'error' })
        }
      },

      buyNft: async nftId => {
        try {
          if (!nftId) {
            return
          }

          const buyNftUrl = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/collection/buy-nft'
          const params = { marketplaceId: nftId }
          const res = await axiosInstance.post(buyNftUrl, params)

          if (res?.status === 200) {
            toastMixin.fire({ title: 'Your have successfully bought the item!' })
          }
        } catch (e) {
          toastMixin.fire({ title: `Error buying NFT: ${e?.response?.data?.error}`, icon: 'error' })
        }
      },

      fetchUserCollection: async color => {
        try {
          const params = {
            color: color || 'w,b',
            pClass: 'p,n,b,r,q,k',
            bCount: 7
          }
          const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/collection/getUserCollection'
          const res = await axiosInstance.get(url, { params })
          if (res?.status === 200) {
            const inUseCollection = {}
            const collection = []
            if (res.data?.data?.length) {
              res.data.data.forEach(ele => {
                if (ele.inUse) {
                  if (ele?.marketplace?.color + ele?.marketplace?.class) {
                    inUseCollection[ele?.marketplace?.color + ele?.marketplace?.class] = ele
                  }
                } else {
                  collection.push(ele)
                }
              })
            }
            set({ collection, inUseCollection, totalCollectionCount: res.data.totalCount })
          }
        } catch (e) {
          console.log('error', e)
          set({ collection: null, inUseCollection: null, totalCollectionCount: 0 })
          toastMixin.fire({ title: `Error fetching your collection: ${e?.response?.data?.error}`, icon: 'error' })
        }
      },
      useCollectionItem: async (pieceDetail, color) => {
        try {
          const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/collection/useNFT/'
          const params = {
            _id: pieceDetail._id
          }
          const res = await axiosInstance.put(url, params)
          if (res?.status === 200) {
            get().fetchUserCollection(color)
          }
        } catch (err) {
          toastMixin.fire({ title: `ERROR using collection: ${err.message}`, icon: 'error' })
          throw new Error(err)
        }
      },
      removeCollectionItem: async (pieceDetail, color) => {
        try {
          const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/collection/removeNFT/'
          const params = {
            _id: pieceDetail._id
          }
          const res = await axiosInstance.put(url, params)
          if (res?.status === 200) {
            get().fetchUserCollection(color)
          }
        } catch (err) {
          toastMixin.fire({ title: `ERROR removing collection: ${err.message}`, icon: 'error' })
          throw new Error(err)
        }
      },

      sendForgotPassOtp: async email => {
        try {
          const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/forgot'
          const res = await axiosInstance.post(url, { email })
          if (res && res.data && res.data.status === 200) {
            toastMixin.fire({
              title: 'OTP send successfully on your mail. Please check it.'
            })
            return res.data
          } else {
            toastMixin.fire({
              title: res.data.message,
              icon: 'error'
            })
          }
        } catch (err) {
          toastMixin.fire({
            title: 'Error sending the mail. Please try again after some time.',
            icon: 'error'
          })
          throw new Error(err)
        }
      },

      resetPassword: async params => {
        try {
          const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/password/update'
          const res = await axiosInstance.post(url, params)
          if (res && res.data && res.data.status === 200) {
            toastMixin.fire({
              title: 'Password updated successfully.'
            })
            return res.data
          } else {
            toastMixin.fire({
              title: res.data.message,
              icon: 'error'
            })
          }
        } catch (err) {
          toastMixin.fire({
            title: 'Error updating password. Please try again after some time.',
            icon: 'error'
          })
          throw new Error(err)
        }
      }
    }),
    {
      name: 'user-storage',
      getStorage: () => localStorage
    }
  )
)

export default useUserStore

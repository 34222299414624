import React from 'react'
import './index.scss'
import { Button } from 'react-bootstrap'
import RankingIcon from '../../../../assets/icons/Ranking.png'
import GemIcon from '../../../../assets/icons/Gem.png'

const LoseGame = ({ onClickOkay }) => {
  return (
    <div className="lose-modal col-lg-12 col-md-10 col-11 mx-auto">
      <div>
        <div className="lose-modal-container">
          <div className="you-lose">
            <h2 className="mb-4 text-center white-text">YOU LOSE!</h2>
          </div>
          <div className="mb-5 mt-5 d-flex justify-content-evenly">
            <div className="eyes-lose">
              <span className="span-minus">
                <img src={GemIcon} alt="gem icon" />
                -500
              </span>
            </div>
            <div className="win-lose">
              <span className="span-plus">
                <img src={RankingIcon} alt="rank icon" />
                +5
              </span>
            </div>
          </div>
          <div className="mb-4 d-flex justify-content-evenly">
            <div className="button-parent">
              <Button className="home-btn" onClick={onClickOkay}>
                HOME
              </Button>
            </div>
            <div className="button-play">
              <Button className="play-btn" onClick={onClickOkay}>
                PLAY AGAIN
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoseGame

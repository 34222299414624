export const PIECES_POINTS = {
  p: 1,
  n: 3,
  b: 3,
  r: 5,
  q: 9
}

export const USER_ROLES = {
  ADMIN: 'admin',
  TEAM_LEADER: 'team_leader',
  USER: 'user'
}
export const MD_DATA = {
  a1: 'wR',
  b1: 'wN',
  c1: 'wB',
  d1: 'wQ',
  e1: 'wK',
  f1: 'wB',
  g1: 'wN',
  h1: 'wR',
  a2: 'wP',
  b2: 'wP',
  c2: 'wP',
  d2: 'wP',
  e2: 'wP',
  f2: 'wP',
  g2: 'wP',
  h2: 'wP',
  a7: 'bP',
  b7: 'bP',
  c7: 'bP',
  d7: 'bP',
  e7: 'bP',
  f7: 'bP',
  g7: 'bP',
  h7: 'bP',
  a8: 'bR',
  b8: 'bN',
  c8: 'bB',
  d8: 'bQ',
  e8: 'bK',
  f8: 'bB',
  g8: 'bN',
  h8: 'bR'
}

export const GEM_TAKEBACK_AMOUNT = 5
export const CHESS_PIECES = ['wP', 'wN', 'wB', 'wR', 'wQ', 'wK', 'bP', 'bN', 'bB', 'bR', 'bQ', 'bK']
export const COLLECTION_PAGE_LIMIT = 12
export const MARKETPLACE_PAGE_LIMIT = 12
export const USER_PAGE_LIMIT = 20
export const HISTORY_PAGE_LIMIT = 12

const PIECE_CLASS = {
  p: true,
  n: true,
  b: true,
  r: true,
  q: true,
  k: true
}
const PIECE_COLOR = { white: true, black: true }
const PIECE_STATUS = { forsale: true, notforsale: true }
export const DEFAULT_FILTER = {
  status: PIECE_STATUS,
  color: PIECE_COLOR,
  pClass: PIECE_CLASS,
  bCount: 7
}

export const CHESSBOARD_START_POSITION = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'

export const GAME_HISTORY_USER_SIDE = {
  0: 'WHITE',
  1: 'BLACK'
}

export const GAME_HISTORY_RESULT = {
  0: 'WIN',
  1: 'LOSE',
  2: 'DRAW'
}

export const PIECE_RARITY = {
  COMMON: 'COMMON',
  AI: 'AI',
  RARE: 'RARE',
  EPIC: 'EPIC',
  HERO: 'HERO',
  NA: 'NA'
}

export const AI_POWER = {
  NEUTRAL_MOVE: 'NEUTRAL_MOVE',
  CASTLING: 'CASTLING',
  NA: 'NA'
}

export const MONTHS = [
  {
    name: 'January',
    value: '1'
  },
  {
    name: 'February',
    value: '2'
  },
  {
    name: 'March',
    value: '3'
  },
  {
    name: 'April',
    value: '4'
  },
  {
    name: 'May',
    value: '5'
  },
  {
    name: 'June',
    value: '6'
  },
  {
    name: 'July',
    value: '7'
  },
  {
    name: 'August',
    value: '8'
  },
  {
    name: 'September',
    value: '9'
  },
  {
    name: 'October',
    value: '10'
  },
  {
    name: 'November',
    value: '11'
  },
  {
    name: 'December',
    value: '12'
  }
]

import React, { useMemo } from 'react'
import { Row } from 'react-bootstrap'
import { Ad1, Ad2, Ad3, Ad4, Ad5 } from '../../../assets/ads'
import './AdsComponent.scss'

const AdsComponent = () => {
  const AdsArray = [Ad1, Ad2, Ad3, Ad4, Ad5]
  const random = useMemo(() => Math.floor(Math.random() * AdsArray.length), [])
  return (
    <Row className="ads-modal">
      <div className="ads-modal__container">
        <img src={AdsArray[random]} alt="ad" />
      </div>
    </Row>
  )
}

export default AdsComponent

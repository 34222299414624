import React from 'react'
import { Container } from 'react-bootstrap'

const Teams = props => {
  return (
    <Container className="p-0">
      <div className="title">Teams</div>
    </Container>
  )
}
export default Teams

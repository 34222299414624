import { bBNft, bKNft, bNNft, bPNft, bQNft, bRNft, wBNft, wKNft, wNNft, wPNft, wQNft, wRNft } from '../assets/nft-pieces'

export const chessPieces = ({ color, type }) => {
  const piece = color.toLowerCase() + type.toLowerCase()
  switch (piece) {
    case 'wp':
      return wPNft
    case 'wr':
      return wRNft
    case 'wn':
      return wNNft
    case 'wb':
      return wBNft
    case 'wq':
      return wQNft
    case 'wk':
      return wKNft
    case 'bp':
      return bPNft
    case 'br':
      return bRNft
    case 'bn':
      return bNNft
    case 'bb':
      return bBNft
    case 'bq':
      return bQNft
    case 'bk':
      return bKNft
    default:
      return null
  }
}

import React from 'react'
import { Button, Container } from 'react-bootstrap'
import './Dashboard.scss'
import axiosInstance from '../../network/axiosInstance'
import { toastErrorMixin, toastMixin } from '../../utils/interact'
const Dashboard = props => {
  const [syncing, setSyncing] = React.useState(false)
  const syncAssetUrls = async user => {
    try {
      setSyncing(true)
      const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/urls/compare-and-update'
      const res = await axiosInstance.put(url)
      if (res.status === 200) {
        toastMixin.fire({
          title: 'Sync Successful'
        })
      }
    } catch (err) {
      toastErrorMixin.fire({
        title: 'Error syncing asset urls.'
      })
    } finally {
      setSyncing(false)
    }
  }

  return (
    <Container className='p-0'>
      <div className="title">
        Dashboard
      </div>
      <div className="button-container">
        <Button disabled={syncing} onClick={syncAssetUrls}>
          {syncing ? 'Syncing' : 'Sync asset URLs'}
        </Button>
      </div>

    </Container>
  )
}
export default Dashboard

import './CustomChessBoard.scss'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ChessPiece from '../ChessPiece'
import { Chess, validateFen } from 'chess.js'
import { White, Black } from '../../assets/boards'
import ChessTarget from '../ChessTarget'
import { toastErrorMixin } from '../../utils/interact'
import axiosInstance from '../../network/axiosInstance'

const ChessBoard = ({ fen, background, setSolutionState, bestLine }) => {
  const [board, setBoard] = useState()
  const [boardOrientation, setBoardOrientation] = useState()
  const chess = useRef(new Chess())
  const [targetMoves, setTargetMoves] = useState()
  const [bgAsset, setBgAsset] = useState()
  const activePiece = useRef()

  useEffect(() => {
    if (!fen || !validateFen(fen).ok) return
    chess.current.load(fen)
    if (chess.current.turn() === 'w') {
      setBgAsset(White[background])
      setBoard(chess.current.board().flat())
      setBoardOrientation('w')
    } else {
      setBgAsset(Black[background])
      setBoardOrientation('b')
    }
    setBoard(chess.current.board().flat())
  }, [fen])

  const getBestMove = useCallback(async (fen, elo = 1500) => {
    try {
      const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/puzzle/best-response'
      const result = await axiosInstance.post(url, { fen, elo })
      return result.data.result
    } catch (err) {
      toastErrorMixin.fire({
        title: 'Error getting best move.'
      })
    }
  }, [])

  const handlePieceClickAndTouch = useCallback((e, piece) => {
    if (e.type === 'mousedown') {
      e.preventDefault()
      e.stopPropagation()
    }
    if (chess.current.turn() !== boardOrientation) return
    activePiece.current = e.currentTarget
    const moves = chess.current.moves({ square: piece.square, verbose: true })
    setTargetMoves(moves)
  }, [boardOrientation])

  const handleChessMove = useCallback(
    async (move) => {
      chess.current.move(move)
      setTargetMoves([])
      setBoard(chess.current.board().flat())
      if (chess.current.isGameOver()) {
        if (chess.current.isCheckmate() && chess.current.turn() === boardOrientation) {
          return setSolutionState('lost')
        } else if (chess.current.isCheckmate() && chess.current.turn() !== boardOrientation) {
          return setSolutionState('won')
        } else {
          return setSolutionState('draw')
        }
      }
      const result = await getBestMove(chess.current.fen(), 1500)
      chess.current.move(result.move)
      setBoard(chess.current.board().flat())
      const history = chess.current.history({ verbose: true })
      let historyText = ''
      history.map((move) => {
        historyText === '' ? historyText = move.lan : historyText = historyText + ' ' + move.lan
        return historyText
      })
      if (chess.current.isGameOver()) {
        if (chess.current.isCheckmate() && chess.current.turn() === boardOrientation) {
          return setSolutionState('lost')
        } else if (chess.current.isCheckmate() && chess.current.turn() !== boardOrientation) {
          return setSolutionState('won')
        } else {
          return setSolutionState('draw')
        }
      }
      if (bestLine === historyText || historyText.includes(bestLine)) return setSolutionState('complete')
      bestLine.includes(historyText) ? setSolutionState('correct') : setSolutionState('wrong')
    },
    [boardOrientation]
  )

  const targetChessMoveHandler = useCallback(async (e, move) => {
    if (e.type === 'mousedown') {
      e.preventDefault()
      e.stopPropagation()
    }
    handleChessMove(move)
  }, [bestLine])

  const squareToCoords = useCallback((square) => {
    // ASCII magic
    const file = parseInt(square[0].charCodeAt(0)) - 97
    const rank = 8 - (parseInt(square[1]))
    return { file: boardOrientation === 'b' ? 7 - file : file, rank: boardOrientation === 'b' ? 7 - rank : rank }
  }, [boardOrientation])

  const mouseAndTouchMoveHandler = useCallback(
    (e, piece) => {
      if (e.type !== 'touchmove') e.stopPropagation()
      if (activePiece.current !== e.currentTarget ||
        chess.current.turn() !== boardOrientation ||
        piece.color !== boardOrientation
      ) return
      const bounds = e.currentTarget.parentElement.getBoundingClientRect()
      const x = e.type === 'mousemove' ? e.clientX - bounds.left : e.touches[0].clientX - bounds.left
      const y = e.type === 'mousemove' ? e.clientY - bounds.top : e.touches[0].clientY - bounds.top
      const width = e.currentTarget.offsetWidth
      const height = e.currentTarget.offsetHeight
      e.currentTarget.style.left = `${x - width / 2}px`
      e.currentTarget.style.top = `${y - height / 2}px`
      e.currentTarget.style.zIndex = 1
    },
    [boardOrientation]
  )

  const handlePieceMouseUpTouchEnd = useCallback(
    (e, piece) => {
      if (e.type !== 'touchend') e.stopPropagation()
      activePiece.current = null
      const board = e.currentTarget.parentElement
      const bounds = board.getBoundingClientRect()
      const x = e.type === 'mouseup' ? e.clientX - bounds.left : e.changedTouches[0].clientX - bounds.left
      const y = e.type === 'mouseup' ? e.clientY - bounds.top : e.changedTouches[0].clientY - bounds.top
      const targetFile = Math.floor(x / (board.offsetWidth / 8))
      const targetRank = Math.floor(y / (board.offsetHeight / 8))
      for (let i = 0; i < targetMoves.length; i = i + 1) {
        const moveCoords = squareToCoords(targetMoves[i].to)
        if (moveCoords.file === targetFile && moveCoords.rank === targetRank) {
          handleChessMove(targetMoves[i])
          return
        }
      }
      e.currentTarget.style.left = `${squareToCoords(piece.square).file * 12.5}%`
      e.currentTarget.style.top = `${squareToCoords(piece.square).rank * 12.5}%`

      const sourceCoords = squareToCoords(piece.square)
      if (sourceCoords.file !== targetFile && sourceCoords.rank !== targetRank) setTargetMoves([])
    },
    [targetMoves]
  )

  return (
    <div className="chess-board"
      onMouseDown={(e) => { e.stopPropagation(); setTargetMoves([]); activePiece.current = null }}
      onContextMenu={(e) => { e.preventDefault() }}
    >
      {board?.map((piece) => {
        return (
          piece && <ChessPiece
            type={piece.type}
            color={piece.color}
            square={piece.square}
            key={piece.square}
            orientation={boardOrientation}
            onMouseDown={(e) => { handlePieceClickAndTouch(e, piece) }}
            onTouchStart={(e) => { handlePieceClickAndTouch(e, piece) }}
            onMouseMove={(e) => { mouseAndTouchMoveHandler(e, piece) }}
            onTouchMove={(e) => { mouseAndTouchMoveHandler(e, piece) }}
            onMouseUp={(e) => { handlePieceMouseUpTouchEnd(e, piece) }}
            onTouchEnd={(e) => { handlePieceMouseUpTouchEnd(e, piece) }}
          />
        )
      })}
      <div className='board-background'>
        <img src={bgAsset} onDragStart={(e) => { e.preventDefault() }}></img>
      </div>
      {targetMoves?.map((move) => {
        return (
          move && <ChessTarget
            square={move.to}
            capture={move.flags.includes('c')}
            key={move.to}
            orientation={boardOrientation}
            onMouseDown={(e) => { targetChessMoveHandler(e, move) }}
            onTouchStart={(e) => { targetChessMoveHandler(e, move) }}
          />
        )
      })}
    </div>
  )
}

export default ChessBoard

import create from 'zustand'
import { persist } from 'zustand/middleware'

const useGlobalStore = create(
  persist(
    (set) => ({
      numberOfActiveUsers: 0,

      setNumberOfActiveUsers: (value) => { set({ numberOfActiveUsers: value }) }
    }),
    {
      name: 'global-storage',
      getStorage: () => localStorage
    }
  )
)

export default useGlobalStore

import React, { useCallback } from 'react'
import './ChessPiece.scss'
import {
  WhiteBishop,
  WhiteKing,
  WhiteKnight,
  WhiteQueen,
  WhitePawn,
  WhiteRook,
  BlackBishop,
  BlackKing,
  BlackKnight,
  BlackQueen,
  BlackPawn,
  BlackRook
} from '../../assets/pieces'

const piecesDict = {
  w: {
    r: WhiteRook,
    n: WhiteKnight,
    b: WhiteBishop,
    q: WhiteQueen,
    k: WhiteKing,
    p: WhitePawn
  },
  b: {
    r: BlackRook,
    n: BlackKnight,
    b: BlackBishop,
    q: BlackQueen,
    k: BlackKing,
    p: BlackPawn
  }
}
const ChessPiece = ({ type, color, square, orientation, onMouseDown, onTouchStart, onMouseMove, onTouchMove, onMouseUp, onTouchEnd }) => {
  const squareToCoords = useCallback((square) => {
    // ASCII magic
    const file = parseInt(square[0].charCodeAt(0)) - 97
    const rank = 8 - (parseInt(square[1]))
    return { file: orientation === 'b' ? 7 - file : file, rank: orientation === 'b' ? 7 - rank : rank }
  }, [])
  return (
    <div className="chess-piece"
      style={{ top: `${squareToCoords(square).rank * 12.5}%`, left: `${squareToCoords(square).file * 12.5}%` }}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
    >
      <img src={piecesDict[color][type]} alt="chess piece" />
    </div>
  )
}

export default ChessPiece

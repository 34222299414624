const socketEvents = {
  // --------------------- Client to Server Socket Events
  CS_JoinRoom: '0xff0001',
  CS_Move: '0xff0002',
  CS_Draw: '0xff0003',
  CS_GiveUp: '0xff0005',
  CS_JoinPvAIRoom: '0xff0006',
  CS_AIMove: '0xff0007',
  CS_AIDraw: '0xff0008',
  CS_AIGiveUp: '0xff0009',
  CS_AIVictory: '0xff000a',
  CS_OfferDraw: '0xff000b',
  CS_Victory: '0xff000c',
  CS_ConfirmDraw: '0xff000d',
  CS_DenyDraw: '0xff000e',
  CS_AIOfferDraw: '0xff000f',
  CS_CLOSEROOM: '0xff0010',
  LEAVEROOM: '0xff0011',
  CS_StartGame: '0xff0012',
  CS_AITAKEBACK: '0xff0013',
  CS_TAKEBACK: '0xff0014',
  // --------------------- Sever to Client Socket Events
  SC_Duplicated: '0x00ff01',
  SC_RoomCreated: '0x00ff02',
  SC_RoomJoined: '0x00ff03',
  SC_Move: '0x00ff04',
  SC_Count: '0x00ff05',
  SC_Timeout: '0x00ff06',
  SC_TokenReward: '0x00ff07',
  SC_GameFinished: '0x00ff08',
  SC_PvAIRoomJoined: '0x00ff09',
  SC_AIMove: '0x00ff0a',
  SC_AIWon: '0x00ff0b',
  SC_AILost: '0x00ff0c',
  SC_ConfirmDraw: '0x00ff0d',
  SC_Draw: '0x00ff0e',
  SC_DrawRejected: '0x00ff0f',
  SC_TokenReward_Error: '0x00ff10',
  SC_AIUPDATEDFEN: '0x00ff11',
  SC_UPDATEDFEN: '0x00ff12',
  // -------------------- Extras
  SEND_NO_USERS: '0x000001',
  GET_NO_USERS: '0x000002',
  DISCONNECT: '0x000003',
  DISCONNECT_OTHER: '0x000004'
}

export default socketEvents

export const Constants = {
  USER: 'user',
  WALLET: 'wallet'
}
